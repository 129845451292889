import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, NavLink } from 'react-router-dom';

import ApiClient from '~/utils/apiClient';
import { useAuth } from '~/hooks/useAuth';
import useDocumentTitle from '~/hooks/useDocumentTitle';
import { ContainerWrapper, Container } from '../layout/container';

import CompanySelectModal from './components/companySelectModal';
import CompanyDocuments from './components/companyDocuments';
import SnapshotSidebar from './reports/sidebar';
import ReportMenu from './reports/menu';
import TrendingSnapshotThemes from './reports/trendingSnapshotThemes';
import SectorComparisonRadarGraph from './reports/sectorComparisonRadar';
import ThemeTreeMap from './reports/themeTreeMap';
import PlatformCounts from '../visualization/platformCounts';
import WeeklyRootThemeCount from '../visualization/weeklyRootThemeCount';
import RecentCompanyContent from './reports/recentContent';
import CompanyExecutives from './reports/executives';

const CompanySnapshot = (): JSX.Element => {
    const { companyId } = useParams<any>();
    const navigate = useNavigate();
    const { isAdmin } = useAuth();

    const [company, setCompany] = useState<any>();
    const [companyIdForFetch, setCompanyIdForFetch] = useState<any>();
    const [lastMonthsRootThemeChange, setLastMonthsRootThemeChange] = useState<any>({
        Environmental: {},
        Social: {},
        Governance: {},
    });

    useDocumentTitle(`${company?.name?.length ? company.name : 'Company'} Report`);

    const getCompany = async (companyId = null) => {
        const cId = companyId || companyIdForFetch;
        if (cId) {
            setCompany(await ApiClient.get(`/api/companies/${cId}`));
        }
    };

    const getLastMonthsRootThemeChange = async (companyId = null) => {
        const cId = companyId || companyIdForFetch;
        if (cId) {
            const lastMonthThemeData = await ApiClient.get(`/api/stats/root-theme-changes?company_id=${cId}`);
            if (lastMonthThemeData) {
                setLastMonthsRootThemeChange({
                    Environmental:
                        lastMonthThemeData.find((category: any) => category.parent_theme_name === 'Environmental') ||
                        {},
                    Social: lastMonthThemeData.find((category: any) => category.parent_theme_name === 'Social') || {},
                    Governance:
                        lastMonthThemeData.find((category: any) => category.parent_theme_name === 'Governance') || {},
                });
            }
        }
    };

    useEffect(() => {
        if (companyId) {
            localStorage.setItem('snapshotCompanyId', companyId);
            setCompanyIdForFetch(companyId);
        } else {
            const savedCompanyId = localStorage.getItem('snapshotCompanyId');
            if (savedCompanyId) {
                navigate(`/app/company/${savedCompanyId}`);
                setCompanyIdForFetch(savedCompanyId);
            }
        }
    }, []);

    useEffect(() => {
        getCompany();
        getLastMonthsRootThemeChange();
        if (companyIdForFetch) {
            localStorage.setItem('snapshotCompanyId', companyIdForFetch);
        }
    }, [companyIdForFetch]);

    return (
        <ContainerWrapper>
            <Container fullWidth>
                <div className="flex flex-col w-full xl:flex-row xl:space-x-10">
                    <div className="flex flex-col xl:w-1/4">
                        {/* <CompanySelectModal
                            handleSelectedOption={(option: string) => {
                                navigate(`/app/company/${option}`);
                                setCompanyIdForFetch(option);
                            }}
                            startOpen={!companyIdForFetch}
                        >
                            <div className="p-2 mb-5 text-xl font-bold text-center bg-white border-2 border-white cursor-pointer rounded-3xl hover:border-verdant">
                                {company?.name ? 'Change Company' : 'Select Company'}
                            </div>
                        </CompanySelectModal> */}
                        {company?.name && (
                            <>
                                {/* <NavLink
                                    to={`/app/company/${companyId}`}
                                    className={({ isActive }) =>
                                        'text-center rounded-3xl bg-white p-2 hover:border-verdant border-2 border-white mb-5 text-xl font-bold' +
                                        (isActive ? ' text-white bg-verdant border-verdant' : '')
                                    }
                                >
                                    Overview
                                </NavLink> */}
                                <SnapshotSidebar companyId={companyIdForFetch} />
                                <ReportMenu companyId={companyIdForFetch} companyName={company.name} />
                                <PlatformCounts companyId={companyIdForFetch} />
                            </>
                        )}
                    </div>
                    <div className="flex flex-col xl:w-3/4">
                        {companyIdForFetch && company?.id && <TrendingSnapshotThemes companyId={companyIdForFetch} />}

                        {companyIdForFetch && company?.id && (
                            <>
                                <div className="inline-block mt-5 mb-3 text-2xl font-bold text-left basis-1/2 2xl:mt-10">
                                    ESG Trends by Week
                                </div>
                                <WeeklyRootThemeCount companyId={companyIdForFetch} />

                                <div className="inline-block mt-5 mb-3 text-2xl font-bold text-left basis-1/2 2xl:mt-10">
                                    Communications Footprint
                                </div>
                                <ThemeTreeMap companyId={companyIdForFetch} companyName={company?.name} />
                                {company?.name &&
                                (lastMonthsRootThemeChange['Environmental']?.count_change ||
                                    lastMonthsRootThemeChange['Social']?.count_change ||
                                    lastMonthsRootThemeChange['Governance']?.count_change) ? (
                                    <>
                                        {Object.keys(lastMonthsRootThemeChange).map((rootThemeName, index) => {
                                            if (lastMonthsRootThemeChange[rootThemeName]?.count_change) {
                                                const changePercent = Number(
                                                    lastMonthsRootThemeChange[rootThemeName]?.log_count_change,
                                                );
                                                const sectorChangePercent = Number(
                                                    lastMonthsRootThemeChange[rootThemeName]?.sector_log_count_change,
                                                );
                                                const changeDirection = changePercent > 0 ? 'up' : 'down';
                                                const sectorChangeDirection = sectorChangePercent > 0 ? 'up' : 'down';
                                                return (
                                                    <div key={`${rootThemeName}-${index}`}>
                                                        <div className="inline-block mt-5 mb-3 text-2xl font-bold text-left basis-1/2 2xl:mt-10">
                                                            {rootThemeName}
                                                        </div>
                                                        <div className="flex flex-col xl:flex-row xl:space-x-5 2xl:mb-10">
                                                            <div className="w-full xl:w-3/4">
                                                                <SectorComparisonRadarGraph
                                                                    companyId={companyIdForFetch}
                                                                    companyName={company.name}
                                                                    rootThemeName={rootThemeName}
                                                                />
                                                            </div>
                                                            <div className="w-full xl:w-1/4">
                                                                <div className="flex self-start mt-5 mb-5 xl:mt-0">
                                                                    <div className="w-full h-full p-5 text-center bg-white rounded-xl">
                                                                        <h2
                                                                            className={`text-5xl my-10 2xl:my-20 ${
                                                                                changePercent > 0
                                                                                    ? 'text-verdant'
                                                                                    : 'text-red-500'
                                                                            }`}
                                                                        >
                                                                            <i
                                                                                className={`fas fa-arrow-${changeDirection} mr-3 fa-2xs`}
                                                                            ></i>
                                                                            <span className="font-bold">
                                                                                {Math.round(changePercent)}%
                                                                            </span>
                                                                        </h2>
                                                                        <p className="italic">
                                                                            Past 30 day{' '}
                                                                            {changeDirection === 'up'
                                                                                ? 'increase'
                                                                                : 'decrease'}{' '}
                                                                            of {rootThemeName} content for{' '}
                                                                            {company.name}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex">
                                                                    <div className="w-full h-full p-5 text-center bg-white rounded-xl">
                                                                        <h2
                                                                            className={`text-5xl my-10 2xl:my-20 ${
                                                                                sectorChangePercent > 0
                                                                                    ? 'text-verdant'
                                                                                    : 'text-red-500'
                                                                            }`}
                                                                        >
                                                                            <i
                                                                                className={`fas fa-arrow-${sectorChangeDirection} mr-3 fa-2xs`}
                                                                            ></i>
                                                                            <span className="font-bold">
                                                                                {Math.round(sectorChangePercent)}%
                                                                            </span>
                                                                        </h2>
                                                                        <p className="italic">
                                                                            Past 30 day{' '}
                                                                            {sectorChangeDirection === 'up'
                                                                                ? 'increase'
                                                                                : 'decrease'}{' '}
                                                                            of {rootThemeName} content for the Sector
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </>
                                ) : null}
                            </>
                        )}
                        {/* {companyIdForFetch && company?.id && isAdmin ? (
                            <RecentCompanyContent companyId={companyIdForFetch} />
                        ) : null} */}
                        {companyIdForFetch && company?.name ? (
                            <CompanyExecutives companyId={companyIdForFetch} />
                        ) : null}
                        {companyIdForFetch && company?.id ? <CompanyDocuments companyId={companyIdForFetch} /> : null}
                    </div>
                </div>
            </Container>
        </ContainerWrapper>
    );
};

export default CompanySnapshot;
