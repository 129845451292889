import React, { useState } from 'react';
import { usePlans } from '../api/getPlans';
import { createCheckoutSession } from '../api/createCheckoutSession';
import { ContainerWrapper } from '~/dashboard/layout/container';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import SkeletonCard from '~/components/skeletonCard';

const Subscription = (): JSX.Element => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { plans, isLoading } = usePlans();

    if (isLoading) {
        return <SkeletonCard />;
    }
    if (!plans) {
        return <></>;
    }

    const checkout = async (priceId: string) => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        const response = await createCheckoutSession(priceId);
        if (response && !response.error) {
            location.href = response.url;
        } else {
            toast.error('There was a problem creating your subscription. Please try again.');
        }
    };

    return (
        <ContainerWrapper className="px-3 background-offset xl:px-0">
            <div className="container flex flex-col mx-auto mt-10">
                <header className="flex flex-row items-center justify-between mb-10">
                    <h1 className="text-3xl font-semibold text-black font-primary">Select a Plan</h1>
                </header>
                <div className="flex flex-col lg:flex-row justify-start items-center lg:space-x-5">
                    {plans.map((plan: any) => (
                        <div
                            className="rounded-xl px-5 py-3 2xl:p-5 bg-white max-w-xs border-2 border-white hover:border-verdant hover:cursor-pointer text-center"
                            key={plan.id}
                            onClick={() => {
                                checkout(plan.prices?.[0]?.id);
                            }}
                        >
                            <div className="flex flex-col justify-center items-center align-middle pb-5">
                                <h2 className="font-primary text-black text-3xl">{plan.name}</h2>
                            </div>
                            <h3 className="font-primary text-lg mt-1">
                                ${plan.prices?.[0]?.unit_amount / 100}
                                <span className="text-base">/{plan.prices?.[0]?.recurring?.interval}</span>
                            </h3>
                            <button disabled={isSubmitting} className="btn-primary text-lg 2xl:text-xl">
                                Select Plan{' '}
                                {isSubmitting && (
                                    <span style={{ maxWidth: 20 }}>
                                        <Loader type="TailSpin" color="#159A1F" height={20} width={20} />
                                    </span>
                                )}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </ContainerWrapper>
    );
};

export default Subscription;
