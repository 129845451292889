import useSWR from 'swr';
import ApiClient from '~/utils/apiClient';
import { Executive } from '../types';

export const getRecentContent = async (url: string) => {
    const response = await ApiClient.get(url);
    if (response.error) {
        throw new Error(response.error);
    }
    return response;
};

export const useRecentContent = (executiveId: string) => {
    const { data, error, isLoading } = useSWR<Executive>(
        `/api/executives/${executiveId}/recent-content`,
        getRecentContent,
    );

    return {
        content: data,
        isLoading,
        isError: error,
    };
};
