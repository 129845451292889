import React, { useState, useEffect } from 'react';
import ApiClient from '~/utils/apiClient';

interface ComponentProps {
    companyId: string;
}

interface Platform {
    name: string;
    total_count: number;
    period_count?: number;
}

const PlatformCounts = (ThemeProps: ComponentProps): JSX.Element => {
    const { companyId } = ThemeProps;
    const [platforms, setPlatforms] = useState<Platform[]>();

    const getPlatforms = async (): Promise<void> => {
        setPlatforms(await ApiClient.get(`/api/stats/platform-breakdown?company_id=${companyId}`));
    };

    useEffect(() => {
        getPlatforms();
    }, [companyId]);

    if (platforms?.length) {
        return (
            <div className="rounded-3xl m p-5 xl:p-8 bg-white flex flex-col mb-5 2xl:mb-10">
                <div className={`flex flex-row justify-between items-center mb-5`}>
                    <h1 className="font-primary font-semibold text-base 2xl:text-2xl text-black">Top Sources</h1>
                </div>

                <div className={`flex flex-col justify-between mb-3 xl:mb-0`}>
                    {platforms.map((platform: Platform) => (
                        <div className="w-full flex flex-row justify-between mb-5 last:mb-0" key={platform.name}>
                            <div>
                                <h3 className="font-primary font-bold text-xl text-black">{platform.name}</h3>
                                <p className="text-gray-400 -mt-1">{platform.total_count} records</p>
                            </div>
                            {!!Number(platform?.period_count) &&
                                platform?.period_count &&
                                platform?.period_count < 0 && (
                                    <span className="text-red-500 pl-2 text-lg">
                                        <i className="fas fa-caret-down pr-1"></i>
                                        {platform?.period_count}
                                    </span>
                                )}
                            {!!Number(platform?.period_count) &&
                                platform?.period_count &&
                                platform?.period_count > 0 && (
                                    <span className="text-verdant pl-2 text-lg">
                                        <i className="fas fa-caret-up pr-1"></i>
                                        {platform?.period_count}
                                    </span>
                                )}
                            {!platform?.period_count && <span className="text-lg">-</span>}
                        </div>
                    ))}
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default PlatformCounts;
