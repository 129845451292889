import React, { useEffect, useState } from 'react';
import ApiClient from '~/utils/apiClient';
import Loading from '~/components/loading';
import { ContainerWrapper } from '../layout/container';
import AlertCard from './components/alertCard';
import ManageAlertModal from './components/manageAlertModal';
import useDocumentTitle from '~/hooks/useDocumentTitle';

export const EmptyAlerts = (): JSX.Element => {
    return (
        <div className="flex flex-col">
            <h2 className="mb-3 text-2xl font-semibold text-black font-primary">No Alerts Yet</h2>
            <p className="text-lg text-black font-primary">
                Create alerts and get instantly notified if your competitor has communicated along a new theme, or
                continues with previous themes
            </p>
        </div>
    );
};

export interface Alert {
    id: number;
    keyword: string | null;
    frequency: 'daily' | 'weekly';
    updated_at: string;
    created_at: string;
    disabled: boolean;
    countries: any;
    media_types: any;
    companies: any;
    themes: any;
}

const Alerts = (): JSX.Element => {
    useDocumentTitle('Alerts');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [alerts, setAlerts] = useState<Alert[]>();
    const [refreshAlerts, setRefreshAlerts] = useState<boolean>(false);

    const getAlerts = async () => {
        const response = await ApiClient.get('/api/alerts');
        setAlerts(response?.alerts);
        setIsLoading(false);
    };

    useEffect(() => {
        getAlerts();
    }, []);

    useEffect(() => {
        if (refreshAlerts) {
            getAlerts();
            setRefreshAlerts(false);
        }
    }, [refreshAlerts]);

    return (
        <ContainerWrapper className="px-3 background-offset xl:px-0">
            <div className="container flex flex-col mx-auto mt-10">
                <header className="flex flex-row items-center justify-between mb-10">
                    <h1 className="text-3xl font-semibold text-black font-primary">Alerts</h1>
                    <ManageAlertModal onClose={() => setRefreshAlerts(true)}>
                        <div className="text-base font-semibold btn-primary">
                            <i className="mr-1 fas fa-plus"></i> New Alert
                        </div>
                    </ManageAlertModal>
                </header>
                {!isLoading && alerts && alerts.length > 0 && (
                    <div className="grid grid-flow-row grid-cols-1 gap-10 mb-10 xl:grid-cols-3 auto-rows-max">
                        {alerts.map((alert: Alert) => {
                            return (
                                <div key={alert.id} className="w-full">
                                    <AlertCard key={alert.id} alert={alert} refreshAlerts={setRefreshAlerts} />
                                </div>
                            );
                        })}
                    </div>
                )}
                {!isLoading && alerts && alerts.length === 0 && (
                    <div className="w-full xl:w-1/3">
                        <div className="flex flex-col px-6 py-4 bg-white rounded-xl">
                            <EmptyAlerts />
                        </div>
                    </div>
                )}
                {isLoading && <Loading />}
            </div>
        </ContainerWrapper>
    );
};

export default Alerts;
