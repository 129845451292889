import React, { useState } from 'react';
import Modal from 'react-modal';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import ApiClient from '~/utils/apiClient';
import { toast } from 'react-toastify';
import FormikAsyncSelect from '~/components/formikAsyncSelect';
import type { Alert } from '../alerts';

interface ComponentProps {
    children: any;
    alert?: Alert;
    onClose: () => void;
}

interface FormInputs {
    keyword: string;
    disabled: boolean;
    frequency: 'daily' | 'weekly';
    media_type_ids: number[];
    theme_ids: number[];
    country_ids: number[];
    company_ids: number[];
}

const ManageAlertModal = (props: ComponentProps): JSX.Element => {
    const { children, alert, onClose } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const closeModal = () => {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    };

    return (
        <div>
            <div onClick={() => setIsOpen(true)}>{children}</div>
            {isOpen ? (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    contentLabel="Manage Alerts"
                    className="cm-modal-body m-w-600"
                    overlayClassName="cm-modal-overlay"
                    ariaHideApp={false}
                >
                    <button className="absolute right-5 text-2xl hover:opacity-80" onClick={closeModal}>
                        <i className="fal fa-times text-vSubtle"></i>
                    </button>
                    <h2 className="font-primary text-2xl mb-5">{alert?.id ? 'Edit Alert' : 'Create Alert'}</h2>

                    <Formik
                        initialValues={{
                            keyword: alert?.keyword || '',
                            frequency: alert?.frequency || 'daily',
                            disabled: alert?.disabled || false,
                            media_type_ids: alert?.media_types || [],
                            theme_ids: alert?.themes || [],
                            country_ids: alert?.countries || [],
                            company_ids: alert?.companies || [],
                        }}
                        onSubmit={async (values: FormInputs, { setSubmitting }: FormikHelpers<FormInputs>) => {
                            let response;
                            if (alert?.id) {
                                response = await ApiClient.update(`/api/alerts/${alert?.id}`, {
                                    alert: { ...values },
                                });
                            } else {
                                response = await ApiClient.create('/api/alerts', {
                                    alert: { ...values },
                                });
                            }

                            if (response && !response.error) {
                                closeModal();
                                toast.success(`${alert?.id ? 'Group updated' : 'New group created'}`);
                                setSubmitting(false);
                            } else {
                                toast.error('There was a problem submitting your group');
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({ isSubmitting, values }) => (
                            <Form>
                                <label
                                    htmlFor="keyword"
                                    className="font-primary text-xl text-black font-semibold block"
                                >
                                    Search Keywords
                                </label>
                                <Field
                                    id="keyword"
                                    name="keyword"
                                    placeholder=""
                                    className="!bg-white cm-input w-full text-xl p-2 mt-2 mb-5"
                                />

                                <label
                                    htmlFor="country_ids"
                                    className="font-primary text-xl text-black font-semibold block"
                                >
                                    Countries
                                </label>
                                <FormikAsyncSelect
                                    fieldProps={{ name: 'country_ids', ...props }}
                                    apiUrl="/api/facets/country"
                                    className="cm-input w-full text-lg mt-2 mb-5"
                                    placeholder="Search for Countries"
                                    multiSelect
                                />

                                <label
                                    htmlFor="company_ids"
                                    className="font-primary text-xl text-black font-semibold block"
                                >
                                    Companies
                                </label>
                                <FormikAsyncSelect
                                    fieldProps={{ name: 'company_ids', ...props }}
                                    apiUrl="/api/facets/company"
                                    className="cm-input w-full text-lg mt-2 mb-5"
                                    placeholder="Search for Companies"
                                    multiSelect
                                />

                                <label
                                    htmlFor="media_type_ids"
                                    className="font-primary text-xl text-black font-semibold block"
                                >
                                    Media Types
                                </label>
                                <FormikAsyncSelect
                                    fieldProps={{ name: 'media_type_ids', ...props }}
                                    apiUrl="/api/facets/media-type"
                                    className="cm-input w-full text-lg mt-2 mb-5"
                                    placeholder="Search for Media Types"
                                    multiSelect
                                />

                                <label
                                    htmlFor="theme_ids"
                                    className="font-primary text-xl text-black font-semibold block"
                                >
                                    Themes
                                </label>
                                <FormikAsyncSelect
                                    fieldProps={{ name: 'theme_ids', ...props }}
                                    apiUrl="/api/facets/theme"
                                    className="cm-input w-full text-lg mt-2 mb-5"
                                    placeholder=""
                                    multiSelect
                                />

                                <div className="block my-8 border-t border-vGray"></div>

                                <label className="font-primary text-xl text-black font-semibold block mb-2">
                                    Email Frequency
                                </label>
                                <div className="flex flex-row gap-5">
                                    <div>
                                        <label
                                            className={`flex p-3 rounded-lg cursor-pointer font-primary text-lg text-black font-semibold items-center ${
                                                values.frequency === 'daily'
                                                    ? 'ring-verdant ring-2'
                                                    : 'border border-gray-200'
                                            }`}
                                            htmlFor="daily"
                                        >
                                            Daily
                                            <div
                                                className={`text-verdant text-xl flex items-center ${
                                                    values.frequency === 'daily' ? '' : 'hidden'
                                                }`}
                                            >
                                                <i className="fad fa-check-circle ml-3"></i>
                                            </div>
                                        </label>

                                        <Field
                                            id="daily"
                                            type="radio"
                                            name="frequency"
                                            value="daily"
                                            className="sr-only"
                                        />
                                    </div>
                                    <div>
                                        <label
                                            className={`flex p-3 rounded-lg cursor-pointer font-primary text-lg text-black font-semibold items-center ${
                                                values.frequency === 'weekly'
                                                    ? 'ring-verdant ring-2'
                                                    : 'border border-gray-200'
                                            } `}
                                            htmlFor="weekly"
                                        >
                                            Weekly
                                            <div
                                                className={`text-verdant text-xl flex items-center ${
                                                    values.frequency === 'weekly' ? '' : 'hidden'
                                                }`}
                                            >
                                                <i className="fad fa-check-circle ml-3"></i>
                                            </div>
                                        </label>
                                        <Field
                                            id="weekly"
                                            type="radio"
                                            name="frequency"
                                            value="weekly"
                                            className="sr-only"
                                        />
                                    </div>
                                </div>
                                {alert?.id && (
                                    <label
                                        htmlFor="disabled"
                                        className="font-primary text-xl text-black font-semibold block mt-5"
                                    >
                                        <span className="pb-2">Disabled</span>
                                        <Field
                                            type="checkbox"
                                            name="disabled"
                                            id="disabled"
                                            className="block h-6 w-6 pt-2"
                                        />
                                    </label>
                                )}

                                <button type="submit" className="btn-primary text-xl mt-10" disabled={isSubmitting}>
                                    {alert?.id ? <>Update Alert</> : <>Create Alert</>}
                                </button>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            ) : (
                <></>
            )}
        </div>
    );
};

export default ManageAlertModal;
