import React from 'react';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import Api from '~/utils/apiClient';

import { Container, ContainerWrapper } from '../layout/container';
import Sidebar from '../layout/sidebar';
import AccountMenu from './menu';
import useDocumentTitle from '~/hooks/useDocumentTitle';

interface AccountForm {
    current_password: string;
    password: string;
    password_confirmation: string;
}

const AccountPassword = (): JSX.Element => {
    useDocumentTitle('Update Password');
    const validateField = (value: string) => {
        if (!value?.length) {
            return 'This field is required';
        }
    };

    return (
        <ContainerWrapper>
            <Sidebar header="Account Menu">
                <AccountMenu />
            </Sidebar>
            <Container className="h-full grow xl:grow-0 xl:h-auto">
                <div className="flex justify-center items-align-center">
                    <div>
                        <h1 className="mb-2 text-2xl 2xl:text-3xl font-primary">Password</h1>

                        <div className="px-5 py-3 bg-white rounded-xl 2xl:p-5" style={{ maxWidth: 500 }}>
                            <Formik
                                initialValues={{
                                    current_password: '',
                                    password: '',
                                    password_confirmation: '',
                                }}
                                onSubmit={async (
                                    values: AccountForm,
                                    { setSubmitting }: FormikHelpers<AccountForm>,
                                ) => {
                                    const response = await Api.update('/account/update-password', {
                                        ...values,
                                    });
                                    if (response && !response.error) {
                                        toast.success('Your password has been updated');
                                        setSubmitting(false);
                                        window.location.replace('/users/sign_in');
                                    } else {
                                        toast.error('There was a problem updating your password');
                                        setSubmitting(false);
                                    }
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <label
                                            htmlFor="current_password"
                                            className="text-lg text-black 2xl:text-xl font-primary"
                                        >
                                            Your Current Password
                                        </label>
                                        <Field
                                            name="current_password"
                                            className="w-full p-2 mb-5 text-lg cm-input 2xl:text-xl"
                                            type="password"
                                            validate={validateField}
                                        />

                                        <label
                                            htmlFor="password"
                                            className="text-lg text-black 2xl:text-xl font-primary"
                                        >
                                            New Password
                                        </label>
                                        <Field
                                            name="password"
                                            className="w-full p-2 mb-5 text-lg cm-input 2xl:text-xl"
                                            type="password"
                                            validate={validateField}
                                        />

                                        <label
                                            htmlFor="password_confirmation"
                                            className="text-lg text-black 2xl:text-xl font-primary"
                                        >
                                            Confirm New Password
                                        </label>
                                        <Field
                                            name="password_confirmation"
                                            className="w-full p-2 text-lg cm-input 2xl:text-xl"
                                            type="password"
                                            validate={validateField}
                                        />

                                        <button
                                            type="submit"
                                            className="mt-10 text-lg btn-primary 2xl:text-xl"
                                            disabled={isSubmitting}
                                        >
                                            Update Password
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </Container>
        </ContainerWrapper>
    );
};

export default AccountPassword;
