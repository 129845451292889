import React from 'react';
import * as Sentry from '@sentry/react';
import { useRouteError } from 'react-router-dom';

const ErrorBoundary = () => {
    const error = useRouteError();
    Sentry.captureException(error);
    return <div>An error has occurred.</div>;
};

export default ErrorBoundary;
