import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import Api from '~/utils/apiClient';

import { Container, ContainerWrapper } from '../layout/container';
import Sidebar from '../layout/sidebar';
import AccountMenu from './menu';
import useDocumentTitle from '~/hooks/useDocumentTitle';

interface AccountForm {
    email: string;
    first_name: string;
    last_name: string;
    id: number;
}

const Account = (): JSX.Element => {
    useDocumentTitle('Profile');
    const [account, setAccount] = useState<any>();

    const getAccount = async () => {
        setAccount(await Api.get('/api/account'));
    };

    const validateField = (value: string) => {
        if (!value?.length) {
            return 'This field is required';
        }
    };

    useEffect(() => {
        getAccount();
    }, []);

    return (
        <ContainerWrapper>
            <Sidebar header="Account Menu">
                <AccountMenu />
            </Sidebar>
            <Container className="h-full grow xl:grow-0 xl:h-auto">
                <div className="flex justify-center items-align-center">
                    <div>
                        <h1 className="mb-2 text-2xl 2xl:text-3xl font-primary">Profile</h1>
                        {account && (
                            <div className="px-5 py-3 bg-white rounded-xl 2xl:p-5">
                                <Formik
                                    initialValues={{
                                        id: account.id,
                                        email: account?.email || '',
                                        first_name: account?.first_name || '',
                                        last_name: account?.last_name || '',
                                    }}
                                    onSubmit={async (
                                        values: AccountForm,
                                        { setSubmitting }: FormikHelpers<AccountForm>,
                                    ) => {
                                        const response = await Api.update('/api/account', {
                                            ...values,
                                        });
                                        if (response && !response.error) {
                                            toast.success('Your profile has been updated');
                                            setSubmitting(false);
                                            if (window.analytics) {
                                                window.analytics.identify(account.id, {
                                                    email: values.email,
                                                    firstName: values.first_name,
                                                    lastName: values.last_name,
                                                });
                                            }
                                        } else {
                                            toast.error('There was a problem updating your profile');
                                            setSubmitting(false);
                                        }
                                    }}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <Field name="id" type="hidden" />
                                            <div className="flex flex-row mb-5">
                                                <div className="pr-5">
                                                    <label
                                                        htmlFor="first_name"
                                                        className="text-lg text-black 2xl:text-xl font-primary"
                                                    >
                                                        First Name
                                                    </label>
                                                    <Field
                                                        name="first_name"
                                                        placeholder=""
                                                        className="w-full p-2 text-lg cm-input 2xl:text-xl"
                                                        validate={validateField}
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        htmlFor="last_name"
                                                        className="text-lg text-black 2xl:text-xl font-primary"
                                                    >
                                                        Last Name
                                                    </label>
                                                    <Field
                                                        name="last_name"
                                                        placeholder=""
                                                        className="w-full p-2 text-lg cm-input 2xl:text-xl"
                                                        validate={validateField}
                                                    />
                                                </div>
                                            </div>
                                            <label
                                                htmlFor="email"
                                                className="text-lg text-black 2xl:text-xl font-primary"
                                            >
                                                Email
                                            </label>
                                            <Field
                                                name="email"
                                                placeholder=""
                                                className="w-full p-2 text-lg cm-input 2xl:text-xl"
                                                type="email"
                                                validate={validateField}
                                            />

                                            <button
                                                type="submit"
                                                className="mt-10 text-lg btn-primary 2xl:text-xl"
                                                disabled={isSubmitting}
                                            >
                                                Update Profile
                                            </button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        </ContainerWrapper>
    );
};

export default Account;
