import React, { useState, useEffect } from 'react';
import ApiClient from '~/utils/apiClient';
import Loading from '~/components/loading';

interface ComponentProps {
    companyId: string;
}

interface Company {
    name: string;
    logo_url: string;
    description: string;
}

const SnapshotSidebar = (props: ComponentProps): JSX.Element => {
    const { companyId } = props;

    const [company, setCompany] = useState<Company>();

    const getCompany = async () => {
        setCompany(await ApiClient.get(`/api/companies/${companyId}`));
    };

    useEffect(() => {
        getCompany();
    }, []);

    useEffect(() => {
        getCompany();
    }, [companyId]);

    return (
        <div>
            {company?.name ? (
                <div className="flex flex-row">
                    <div className="bg-white rounded-2xl flex flex-col justify-center items-center mb-5 p-10 w-full">
                        {company?.logo_url ? (
                            <img
                                alt={'company logo'}
                                src={company?.logo_url}
                                className="rounded-2xl mx-auto max-w-full bg-white"
                            />
                        ) : null}
                        <h1 className="font-primary font-bold text-2xl text-black text-center w-full mt-3">
                            {company.name}
                        </h1>
                        {company?.description ? (
                            <p className="font-primary font-medium text-base text-black">{company.description}</p>
                        ) : null}
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default SnapshotSidebar;
