import useSWR from 'swr';
import { Executive } from '~/dashboard/executives';
import ApiClient from '~/utils/apiClient';

export const getFeaturedExecutive = async (url: string) => {
    const response = await ApiClient.get(url);
    if (response.error) {
        throw new Error(response.error);
    }
    return response;
};

export const useFeaturedExecutive = (executive_id: string) => {
    const { data, error, isLoading } = useSWR<Executive>(`/api/executives/${executive_id}`, getFeaturedExecutive);

    return {
        executive: data,
        isLoading,
        isError: error,
    };
};
