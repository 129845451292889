import React, { useState } from 'react';
import Modal from 'react-modal';
import Spinner from '~/components/spinner';
import { useAuth } from '~/hooks/useAuth';
import { Configure, InstantSearch, SearchBox, useHits, UseHitsProps } from 'react-instantsearch';

interface SearchResultsProps extends UseHitsProps {
    handleSelectedOption: any;
}

const SearchResults = (props: SearchResultsProps): JSX.Element => {
    const { hits, results } = useHits(props);
    const { handleSelectedOption } = props;
    if (!hits || typeof results?.nbHits != 'undefined') {
        return (
            <>
                {hits.map((hit: any) => (
                    <div
                        key={hit.id}
                        className="flex flex-row px-5 mb-1 rounded-xl justify-between item  s-center w-full hover:bg-gray-100 cm-border cursor-pointer active:border-verdant"
                        onClick={() => {
                            handleSelectedOption(hit.id);
                        }}
                    >
                        <div className="flex flex-col py-2">
                            <h3 className="font-primary font-semibold text-lg text-black">{hit.name}</h3>
                        </div>
                    </div>
                ))}
            </>
        );
    } else {
        return <Spinner />;
    }
};

interface ComponentProps {
    children?: JSX.Element;
    handleSelectedOption: any;
    startOpen?: boolean;
}

const CompanySelectModal = (props: ComponentProps): JSX.Element => {
    const { children, handleSelectedOption, startOpen } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { meili, meiliClient } = useAuth();

    const closeModal = (): void => {
        setIsOpen(false);
        setIsSubmitting(false);
    };

    return (
        <div>
            <div onClick={() => setIsOpen(true)}>{children ? children : null}</div>
            {isOpen ? (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    contentLabel="Domain Modal"
                    className="cm-modal-body"
                    overlayClassName="cm-modal-overlay"
                    ariaHideApp={false}
                >
                    {!startOpen ? (
                        <button className="absolute text-2xl right-5 hover:opacity-80" onClick={closeModal}>
                            <i className="fal fa-times text-vSubtle"></i>
                        </button>
                    ) : null}

                    <h2 className="mb-10 text-2xl font-primary">Select Company</h2>
                    {meili && meiliClient ? (
                        <InstantSearch indexName={meili.indexes['company']} searchClient={meiliClient}>
                            <Configure hitsPerPage={5} />
                            <div className="flex flex-col mx-auto mt-10">
                                <SearchBox className="multisearch" />
                            </div>
                            <div className="mt-5">
                                <SearchResults handleSelectedOption={handleSelectedOption} />
                            </div>
                        </InstantSearch>
                    ) : (
                        <Spinner />
                    )}
                </Modal>
            ) : null}
        </div>
    );
};

export default CompanySelectModal;
