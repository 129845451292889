import React from 'react';
import { useHits, UseHitsProps } from 'react-instantsearch';
import ResultCard from '../content/resultCard';
import Loading from '~/components/loading';
import { useAuth } from '~/hooks/useAuth';

const CardRow = (props: UseHitsProps): JSX.Element => {
    const { isSubscribed } = useAuth();
    const { hits, results } = useHits(props);
    if (!hits || typeof results?.nbHits != 'undefined') {
        let resultHits = hits;

        if (resultHits && resultHits?.length && !isSubscribed) {
            resultHits = hits.slice(0, 5);
        }
        return (
            <div>
                {resultHits.map((hit: any) => {
                    return <ResultCard hit={hit} key={hit.objectID || hit.uuid} />;
                })}
            </div>
        );
    } else {
        return <Loading />;
    }
};

export default CardRow;
