import React, { Dispatch, SetStateAction } from 'react';
import DeleteWithConfirmation from '~/components/deleteWithConfirmation';
import ApiClient from '~/utils/apiClient';
import { toast } from 'react-toastify';

interface ComponentProps {
    alert: any;
    children: any;
    refreshAlerts: Dispatch<SetStateAction<boolean>>;
}

const DeleteAlert = (props: ComponentProps): JSX.Element => {
    const { alert, children, refreshAlerts } = props;

    const deleteAlert = async (alertId: number) => {
        const deleteResponse = await ApiClient.delete(`/api/alerts/${alertId}`);
        if (deleteResponse.message) {
            toast.success('Alert deleted');
        } else {
            toast.error('There was a problem deleting your alert');
        }
        refreshAlerts(true);
    };

    return (
        <DeleteWithConfirmation onConfirmation={deleteAlert} id={alert.id} name={'this alert'} title="Delete Alert">
            {children}
        </DeleteWithConfirmation>
    );
};

export default DeleteAlert;
