import React from 'react';
import { useRecentContent } from '../api/getRecentContent';

import ResultCard from '~/dashboard/content/resultCard';
import { NavLink, useParams } from 'react-router-dom';
import { useExecutive } from '../api/getExecutive';
import { useSearchUrls } from '~/hooks/useSearchUrls';

const RecentContent = (): JSX.Element => {
    const { executiveId } = useParams<string>();
    const { generateSearchByExecutiveUrl } = useSearchUrls();
    if (!executiveId) return <></>;
    const { content } = useRecentContent(executiveId);
    const { executive } = useExecutive(executiveId);

    if (!content || !executive) {
        return <></>;
    }

    return (
        <section className="mt-10 pt-5 border-t border-gray-200">
            <div className="flex flex-row justify-between align-text-bottom">
                <h1 className="font-normal mb-5">Most Recent Comms</h1>
                <NavLink
                    to={generateSearchByExecutiveUrl(`${executive.name} - ${executive.company.name}`)}
                    className="text-verdant hover:text-verdant underline"
                >
                    Explore Content
                </NavLink>
            </div>
            <div className="grid grid-cols-3 gap-x-10">
                {content?.map((item: any) => (
                    <div className="flex" key={item.id}>
                        <ResultCard hit={item} hideFooter hideBorder truncateText />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default RecentContent;
