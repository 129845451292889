import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import ApiClient from '~/utils/apiClient';
import Spinner from '~/components/spinner';
import { useSearchUrls } from '~/hooks/useSearchUrls';

interface ComponentProps {
    title?: string;
    companyId?: string;
    companyPage?: boolean;
    maxWidth?: number;
}

const RootThemesDonutChart = (props: ComponentProps): JSX.Element => {
    const { generateSearchByCompanyUrl } = useSearchUrls();

    const { title, companyId, companyPage } = props;

    const [companyData, setCompanyData] = useState<any>();
    const [chartData, setChartData] = useState<any>();
    const [chartOptions, setChartOptions] = useState<any>();

    const options = {
        colors: ['#3B82F6', '#F59E0B', '#D946EF'],
        chart: {
            height: 390,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                    margin: 5,
                    size: '30%',
                    background: 'transparent',
                    image: undefined,
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        show: false,
                    },
                },
            },
        },
        labels: [],
        legend: {
            show: true,
            floating: true,
            fontSize: '16px',
            position: 'left',
            offsetX: 0,
            offsetY: 8,
            labels: {
                useSeriesColors: true,
            },
            markers: {
                size: 0,
            },
            formatter: function (seriesName, opts) {
                return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex];
            },
            itemMargin: {
                vertical: 2,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        show: false,
                    },
                },
            },
        ],
    };

    const getChartData = async () => {
        setCompanyData(undefined);
        setChartData(undefined);
        const data = await ApiClient.get(
            `/api/stats/root-theme-breakdown${companyId ? `?company_id=${companyId}` : ``}`,
        );

        if (data && !data?.error) {
            setCompanyData({
                companyName: data?.company,
                logoUrl: data?.logo_url,
            });

            options.labels = ['Environmental', 'Social', 'Governance'];
            setChartOptions(options);

            const orderedData = [];
            orderedData.push(
                data?.find((category: any) => category.parent_theme_name === 'Environmental')?.['count'] || 0,
            );
            orderedData.push(data?.find((category: any) => category.parent_theme_name === 'Social')?.['count'] || 0);
            orderedData.push(
                data?.find((category: any) => category.parent_theme_name === 'Governance')?.['count'] || 0,
            );
            setChartData(orderedData);
        }
    };

    useEffect(() => {
        getChartData();
    }, []);

    useEffect(() => {
        getChartData();
    }, [companyId]);

    return (
        <div className="flex flex-col p-5 mb-5 bg-white rounded-xl 2xl:mb-10">
            <div className="flex flex-col justify-center w-full">
                <div className={` ${companyPage ? ' hidden ' : ' '}flex flex-row justify-center items-center mb-3`}>
                    <h1 className="text-base font-semibold text-black font-primary 2xl:text-lg">
                        {title ? title : 'Theme Breakdown'}
                        {companyData?.companyName && !title && !companyPage ? (
                            <span>
                                <a
                                    className="text-base text-black font-primary grow hover:text-verdant"
                                    href={generateSearchByCompanyUrl(companyData.companyName)}
                                >
                                    {companyData.companyName}{' '}
                                </a>
                                Theme Breakdown
                            </span>
                        ) : null}
                    </h1>
                    {companyData && companyData?.logoUrl && !title ? (
                        <a href={generateSearchByCompanyUrl(companyData.companyName)}>
                            <img src={companyData?.logoUrl} className="w-8 h-auto min-h-full" />
                        </a>
                    ) : null}
                </div>
                {chartData ? (
                    <ReactApexChart
                        options={chartOptions}
                        series={chartData}
                        type="radialBar"
                        className="flex justify-center"
                        width={390}
                    />
                ) : (
                    <Spinner />
                )}
                {companyId && !chartData && !setCompanyData && <p>No results</p>}
            </div>
        </div>
    );
};

export default RootThemesDonutChart;
