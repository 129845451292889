import React from 'react';

const Header = ({ title, description }: { title: string; description: string }): JSX.Element => {
    return (
        <div className="flex flex-col mb-10">
            <h1 className="text-2xl font-semibold text-black font-primary 2xl:text-3xl">{title}</h1>
            <p className=" text-black font-primary">{description}</p>
        </div>
    );
};

export default Header;
