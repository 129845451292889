import React from 'react';
import SkeletonCard from '~/components/skeletonCard';
import { Card, CardContent, CardDescription, CardHeader } from '~/components/ui/card';

import { useExecutive } from '../api/getExecutive';
import { useParams } from 'react-router-dom';

const ThumbnailAndBio = (): JSX.Element => {
    const { executiveId } = useParams<string>();
    if (!executiveId) return <></>;
    const { executive, isLoading } = useExecutive(executiveId);

    if (isLoading) {
        return <SkeletonCard />;
    }
    if (!executive) {
        return <></>;
    }
    return (
        <Card className="border-0 shadow-none">
            <CardHeader className="pt-0">
                <img
                    alt="Executive Thumbnail"
                    src={executive.profile_thumbnail_url}
                    className="bg-white rounded-xl w-full max-w-[150px] object-cover aspect-square"
                />
            </CardHeader>
            <CardContent>
                <h2>{executive.name}</h2>
                <CardDescription>
                    {executive.title} @ {executive.company.name}
                </CardDescription>
                <p>{executive.biography}</p>
            </CardContent>
        </Card>
    );
};

export default ThumbnailAndBio;
