import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Overview from './overview';

export const OverviewRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Overview />} />
        </Routes>
    );
};
