import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Subscription from './subscription';

export const BillingRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Subscription />} />
            <Route path="*" element={<Navigate to="." />} />
        </Routes>
    );
};
