import React from 'react';
import { useTopVoicesByTheme } from '../api/getTopVoicesByTheme';

import ResultCard from '~/dashboard/executives/components/search/resultCard';
import { Executive } from '~/dashboard/executives';

const TopVoicesByTheme = (): JSX.Element => {
    const { executives } = useTopVoicesByTheme('AI Ethics');

    if (!executives) {
        return <></>;
    }
    return (
        <>
            <h2 className="text-xl mt-10 mb-5">Top Executive Voices on AI Ethics</h2>
            <div className="flex flex-col lg:flex-row justify-between lg:space-x-10 space-y-5 lg:space-y-0">
                {executives.map((executive: Executive) => (
                    <ResultCard hit={executive} key={executive.uuid} />
                ))}
            </div>
        </>
    );
};

export default TopVoicesByTheme;
