import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'jotai';

import AppRoutes from './routes/index';
import { AuthProvider } from './authContext';
import { SavedContentProvider } from './savedContentContext';

document.addEventListener(
    'DOMContentLoaded',
    () => {
        const domNode = document.getElementById('dashboard');
        if (domNode) {
            const rootMount = createRoot(domNode);
            rootMount.render(
                <React.StrictMode>
                    <Provider>
                        <AuthProvider>
                            <SavedContentProvider>
                                <AppRoutes />
                            </SavedContentProvider>
                        </AuthProvider>
                    </Provider>
                </React.StrictMode>,
            );
        }
    },
    false,
);
