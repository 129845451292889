import React from 'react';
import { Link } from 'react-router-dom';
import { Configure, InstantSearch, SearchBox, useHits, UseHitsProps } from 'react-instantsearch';

import Spinner from '~/components/spinner';
import { useAuth } from '~/hooks/useAuth';
import { ContainerWrapper } from '../layout/container';

interface HitCompany {
    id: number;
    name: string;
    company_id: string;
    description?: string;
    logo_url?: string;
    slug?: string;
}

const CardRow = (props: UseHitsProps): JSX.Element => {
    const { hits, results } = useHits(props);
    if (!hits || typeof results?.nbHits != 'undefined') {
        return (
            <>
                {hits.map((hit: any) => {
                    if (hit.active) {
                        return <CompanyHit hit={hit} key={hit.objectID || hit.id} />;
                    } else {
                        return null;
                    }
                })}
            </>
        );
    } else {
        return <Spinner />;
    }
};

const CompanyHit = ({ hit }: { hit: HitCompany }): JSX.Element => {
    return (
        <Link
            to={`/app/company/${hit.slug || hit.id}`}
            key={hit.id}
            className="flex flex-col items-center w-full p-5 space-x-0 bg-white border-2 border-transparent cursor-pointer xl:space-x-5 xl:flex-row rounded-2xl hover:border-verdant"
        >
            {hit.logo_url ? (
                <img
                    alt="company logo"
                    src={hit.logo_url}
                    className="h-auto max-w-xs mx-auto bg-white rounded-xl aspect-square"
                    style={{ maxWidth: 50, maxHeight: 50 }}
                />
            ) : null}
            <div className="flex flex-col items-center grow">
                <h2 className="w-full text-xl font-bold text-center text-black font-primary xl:text-left">
                    {hit.name}
                </h2>
            </div>
        </Link>
    );
};

const Companies = (): JSX.Element => {
    const { meili, meiliClient } = useAuth();
    return (
        <ContainerWrapper className="px-5 background-offset">
            {meili && meiliClient ? (
                <InstantSearch indexName={meili.indexes['company']} searchClient={meiliClient} routing={true}>
                    <Configure hitsPerPage={1000} />
                    <div className="container flex flex-col mx-auto mt-10">
                        <header className="flex flex-row items-center justify-between mb-10">
                            <h1 className="text-3xl font-semibold text-black font-primary">Companies</h1>
                            <SearchBox />
                        </header>
                        <div className="grid grid-flow-row grid-cols-1 gap-10 mb-10 sm:grid-cols-2 xl:grid-cols-4 auto-rows-max">
                            <CardRow />
                        </div>
                    </div>
                </InstantSearch>
            ) : (
                <Spinner />
            )}
        </ContainerWrapper>
    );
};

export default Companies;
