import React, { useEffect, useState } from 'react';

import ReactApexChart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';

import ApiClient from '~/utils/apiClient';
import Spinner from '~/components/spinner';
interface ComponentProps {
    title?: string;
    companyId?: string;
    companyPage?: boolean;
    maxWidth?: number;
    height?: number;
}

const WeeklyRootThemeCount = (props: ComponentProps): JSX.Element => {
    const { title, companyId, companyPage, height } = props;

    const chartHeight = height ? height : 500;

    const [chartData, setChartData] = useState<any>();
    const [chartOptions, setChartOptions] = useState<any>();

    const options: ApexOptions = {
        colors: ['#3B82F6', '#F59E0B', '#D946EF'],
        chart: {
            height: chartHeight,
            type: 'line',
            zoom: {
                enabled: true,
                zoomedArea: {
                    fill: {
                        color: '#159A1F',
                        opacity: 0.1,
                    },
                    stroke: {
                        color: '#159A1F',
                        opacity: 0.1,
                        width: 2,
                    },
                },
            },
        },
        legend: {
            fontSize: '16px',
            fontFamily: 'cmFont, AvenirLight, sans-serif',
            fontWeight: 700,

            markers: {
                width: 22,
                height: 2.5,
                radius: 10,
                offsetX: -3,
            },
            itemMargin: {
                horizontal: 10,
            },
        },
        grid: {
            yaxis: {
                lines: {
                    show: false,
                },
            },
            row: {
                colors: ['#EFF3F4', 'transparent'],
                opacity: 0.2,
            },
            padding: {
                top: 15,
            },
        },
        stroke: {
            curve: 'smooth',
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            y: {
                formatter: function (value: number, { _series, _seriesIndex, _dataPointIndex, _w }) {
                    return `${value} Records`;
                },
            },
            style: {
                fontFamily: 'cmFont, AvenirLight, sans-serif',
            },
        },
        yaxis: {
            labels: {
                show: false,
            },
        },
        xaxis: {
            categories: [],
            type: 'datetime',
            labels: {
                show: true,
                style: {
                    fontFamily: 'cmFont, AvenirLight, sans-serif',
                },
            },
            tooltip: {
                style: {
                    fontFamily: 'cmFont, AvenirLight, sans-serif',
                },
            },
        },
    };

    const getChartData = async () => {
        setChartData(undefined);
        let data;
        if (companyId) {
            data = await ApiClient.get(`/api/stats/company-weekly-counts?company_id=${companyId}`);
        } else {
            data = await ApiClient.get(`/api/stats/global-weekly-counts`);
        }

        if (data && !data?.error) {
            if (options?.xaxis) {
                options.xaxis.categories = data.timestamps;
            }
            setChartOptions(options);
            setChartData(data.series);
        }
    };

    useEffect(() => {
        getChartData();
    }, []);

    useEffect(() => {
        getChartData();
    }, [companyId]);

    return (
        <div className="flex flex-col p-5 mb-5 bg-white rounded-xl 2xl:mb-10">
            <div className="flex flex-col justify-center w-full">
                {title && <h1 className="text-base font-semibold text-black font-primary 2xl:text-lg">{title}</h1>}
                {chartData && chartOptions ? (
                    <ReactApexChart
                        options={chartOptions}
                        series={chartData}
                        type="line"
                        className="flex justify-center"
                        height={chartHeight}
                    />
                ) : (
                    <Spinner />
                )}
                {/* {companyId && !chartData && <p>No results</p>} */}
            </div>
        </div>
    );
};

export default WeeklyRootThemeCount;
