import React from 'react';

import { useRecentContentByTheme } from '../api/getRecentContentByTheme';
import SkeletonCard from '~/components/skeletonCard';
import ResultCard from '~/dashboard/content/resultCard';

const RecentContentByTheme = () => {
    const { content, isLoading } = useRecentContentByTheme('AI Ethics');

    if (isLoading) {
        return <SkeletonCard />;
    }
    if (!content) {
        return <></>;
    }
    return (
        <>
            <h2 className="text-xl mt-10 mb-5">Recent AI Ethics Content</h2>
            {content.map((c: any) => (
                <ResultCard hit={c} key={c.uuid} />
            ))}
        </>
    );
};

export default RecentContentByTheme;
