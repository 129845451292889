import React from 'react';

import NavBar from '../components/navBar';
import ThumbnailAndBio from '../components/thumbnailAndBio';
import OverviewHeader from '../components/overviewHeader';
import ThemeTrends from '../components/themeTrends';
import ThemeChart from '../components/themeChart';
import RecentContent from '../components/recentContent';
import TopSources from '../components/topSources';
import Documents from '../components/documents';
import Competitors from '../components/competitors';
import RelatedExecutives from '../components/relatedExecutives';
import ThemeComparison from '../components/themeComparison';
import ExecutiveThemeComparison from '~/dashboard/overview/components/executiveThemeComparison';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '~/components/ui/hover-card';
import { useParams } from 'react-router-dom';

const Executive = (): JSX.Element => {
    const { executiveId } = useParams<string>();
    return (
        <div className="pb-32">
            <NavBar />
            <div className="flex flex-row px-20 mt-10 space-x-20">
                <div className="flex flex-col w-4/5">
                    <OverviewHeader />

                    <section className="mt-10 pt-5 border-t border-gray-200">
                        <div className="flex flex-row justify-between align-text-bottom">
                            <h1 className="font-normal mb-5">
                                Top Themes{' '}
                                <HoverCard>
                                    <HoverCardTrigger className="text-base ml-1 text-gray-500">
                                        <i className="far fa-info-circle hover:text-cometrics"></i>
                                    </HoverCardTrigger>
                                    <HoverCardContent className="w-96 border-cometrics">
                                        <p>
                                            This section showcases the top 5 themes most frequently mentioned by the
                                            Executive, their Company, and the overall sector they operate in.
                                        </p>
                                    </HoverCardContent>
                                </HoverCard>
                            </h1>
                        </div>

                        <ExecutiveThemeComparison executiveId={executiveId} noPadding />
                    </section>
                    <RecentContent />
                    {/*<ThemeChart />
                     <ThemeTrends />
                    <TopSources />
                    <Documents />
                    <Competitors /> */}
                </div>
                <div className="flex flex-col border-l border-gray-200">
                    <ThumbnailAndBio />
                    <RelatedExecutives />
                </div>
            </div>
        </div>
    );
};

export default Executive;
