import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

import AccountDropDown from '../settings/dropdown';
import { useAuth } from '~/hooks/useAuth';
import { cn } from '~/utils/ui';

import Logo from '~/images/logo-refresh.svg';

const MenuItems = ({ isAdmin }: { isAdmin: boolean }): JSX.Element => {
    return (
        <nav>
            <ul className="flex flex-col xl:flex-row">
                <NavLink
                    to={`/app/dashboard`}
                    className={({ isActive }) =>
                        cn('px-5 text-black hover:text-verdant mb-5 xl:mb-0 mt-3 xl:mt-0', isActive && 'verdant')
                    }
                >
                    <i className="mr-2 text-xl fal fa-home-alt fa-fw xl:text-base text-verdant"></i>
                    <span className="font-size-1em">Home</span>
                </NavLink>
                <NavLink
                    to={`/app/search`}
                    className={({ isActive }) =>
                        cn('px-5 text-black hover:text-verdant mb-5 xl:mb-0 mt-3 xl:mt-0', isActive && 'verdant')
                    }
                >
                    <i className="mr-2 text-xl fal fa-file-search fa-fw xl:text-base text-verdant"></i>
                    <span className="font-size-1em">Search Content</span>
                </NavLink>
                <NavLink
                    to={`/app/executives`}
                    className={({ isActive }) =>
                        cn('px-5 text-black hover:text-verdant mb-5 xl:mb-0 mt-3 xl:mt-0', isActive && 'verdant')
                    }
                >
                    <i className="mr-2 text-xl fal fa-users fa-fw xl:text-base text-verdant"></i>
                    <span className="font-size-1em">Executives</span>
                </NavLink>
                <NavLink
                    to={`/app/waitlist`}
                    className={({ isActive }) =>
                        cn(
                            'px-5 text-muted-foreground hover:text-verdant mb-5 xl:mb-0 mt-3 xl:mt-0',
                            isActive && 'text-muted-foreground',
                        )
                    }
                >
                    <i className="mr-2 text-xl fal fa-analytics fa-fw xl:text-base"></i>
                    <span className="font-size-1em">Companies</span>
                </NavLink>
                <NavLink
                    to={`/app/waitlist`}
                    className={({ isActive }) =>
                        cn(
                            'px-5 text-muted-foreground hover:text-verdant mb-5 xl:mb-0 mt-3 xl:mt-0',
                            isActive && 'text-muted-foreground',
                        )
                    }
                >
                    <i className="mr-2 text-xl fal fa-people-group fa-fw xl:text-base"></i>
                    <span className="font-size-1em">Boards</span>
                </NavLink>
                <div className="flex flex-col xl:hidden">
                    <NavLink
                        to={`/app/saved`}
                        className={({ isActive }) =>
                            cn('px-5 text-black hover:text-verdant mb-5 xl:mb-0 mt-3 xl:mt-0', isActive && 'verdant')
                        }
                    >
                        <i className="mr-2 text-xl fal fa-heart fa-fw xl:text-base text-verdant"></i>
                        <span className="font-size-1em">Saved</span>
                    </NavLink>
                    <NavLink
                        to={`/app/alerts`}
                        className={({ isActive }) =>
                            cn('px-5 text-black hover:text-verdant mb-5 xl:mb-0 mt-3 xl:mt-0', isActive && 'verdant')
                        }
                    >
                        <i className="mr-2 text-xl fal fa-bell fa-fw xl:text-base text-verdant"></i>
                        <span className="font-size-1em">Alerts</span>
                    </NavLink>
                    <NavLink
                        to={`/app/nominate/executives`}
                        className={({ isActive }) =>
                            cn('px-5 text-black hover:text-verdant mb-5 xl:mb-0 mt-3 xl:mt-0', isActive && 'verdant')
                        }
                    >
                        <i className="mr-2 text-xl fal fa-bullhorn fa-fw xl:text-base text-verdant"></i>
                        <span className="font-size-1em">Nominate</span>
                    </NavLink>
                    <NavLink
                        to={`/app/settings/profile`}
                        className={({ isActive }) =>
                            'px-5 text-black hover:text-verdant mb-5 xl:mb-0 mt-3 xl:mt-0' +
                            (isActive ? ' verdant' : '')
                        }
                    >
                        <i className="mr-2 text-xl fal fa-user fa-fw xl:text-base text-verdant"></i>
                        <span className="font-size-1em">Account</span>
                    </NavLink>
                    {isAdmin ? (
                        <>
                            <div className="my-5 mt-0 border-t border-gray-200"></div>
                            <a href="/admin" className="px-5 text-black hover:text-verdant xl:mb-0">
                                <i className="mr-2 text-xl fal fa-screwdriver-wrench fa-fw xl:text-base text-verdant"></i>
                                <span className="font-size-1em">Admin Panel</span>
                            </a>
                        </>
                    ) : null}
                    <div className="my-5 border-t border-gray-200"></div>
                    <a href="/users/sign_out" className="px-5 mb-5 text-red-500 hover:text-red-700 xl:mb-0">
                        <i className="mr-2 text-xl fal fa-sign-out fa-fw xl:text-base"></i>
                        <span className="font-size-1em">Log Out</span>
                    </a>
                </div>
            </ul>
        </nav>
    );
};

const RegisterOrLogin = (): JSX.Element => {
    return (
        <div className="flex flex-col items-center xl:flex-row">
            <NavLink
                to="/users/login"
                reloadDocument
                state={{ path: location.pathname }}
                className="px-3 mt-5 mr-3 xl:mt-0"
            >
                Login
            </NavLink>
            <a href="https://www.cometrics.io/register" className="mt-5 xl:mt-0 btn-primary">
                Request Demo
            </a>
        </div>
    );
};

const Menu = (): JSX.Element => {
    const { isAuthenticated, isAdmin, user } = useAuth();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    return (
        <nav className="fixed inset-x-0 top-0 z-30 flex flex-row items-center justify-between w-full h-16 px-5 text-lg  align-middle bg-white border-b-2 border-gray-200 font-primary">
            <div className="w-2/3 xl:w-1/6">
                <Link to="/app/dashboard">
                    {user?.team?.whitelabel_logo_url && user?.team?.use_whitelabel_logo ? (
                        <img
                            src={user.team.whitelabel_logo_url}
                            style={{ maxWidth: 225, maxHeight: 30 }}
                            className="h-auto min-h-full"
                        />
                    ) : (
                        <img src={Logo} style={{ maxWidth: 225 }} />
                    )}
                </Link>
            </div>
            <div className="flex-row justify-center hidden xl:flex grow">
                {isAuthenticated ? <MenuItems isAdmin={isAdmin} /> : null}
            </div>
            <div className="justify-end hidden w-1/6 xl:flex">
                {isAuthenticated ? <AccountDropDown /> : <RegisterOrLogin />}
            </div>
            <div className="flex justify-end w-1/3 xl:hidden">
                <div
                    className="pl-5 text-black cursor-pointer hover:text-green-600 font-size-1em"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <i className="fal fa-bars"></i>
                </div>
            </div>
            <main
                className={`fixed overflow-hidden top-16 z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out
                    ${
                        isOpen
                            ? 'transition-opacity opacity-100 duration-500 translate-x-0'
                            : 'transition-all delay-500 opacity-0 translate-x-full'
                    }
                `}
            >
                <section
                    className={`w-screen max-w-sm right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform
                        ${isOpen ? 'translate-x-0' : 'translate-x-full'}
                    `}
                >
                    <article
                        className="relative flex flex-col w-screen h-full max-w-sm pb-10 space-y-6 overflow-y-auto text-xl"
                        onClick={() => setIsOpen(false)}
                    >
                        {isAuthenticated ? <MenuItems isAdmin={isAdmin} /> : <RegisterOrLogin />}
                    </article>
                </section>
                <section
                    className="w-screen h-full cursor-pointer"
                    onClick={() => {
                        setIsOpen(false);
                    }}
                ></section>
            </main>
        </nav>
    );
};

export default Menu;
