import React from 'react';
import SearchAndFilter from '../components/search/searchAndFilter';

const Executives = (): JSX.Element => {
    return (
        <>
            <SearchAndFilter />
        </>
    );
};

export default Executives;
