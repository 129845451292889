import React from 'react';
import { useParams } from 'react-router-dom';
import CompetitorCard from './reports/competitorCard';
import { ContainerWrapper, Container } from '../layout/container';
import useDocumentTitle from '~/hooks/useDocumentTitle';

interface ComponentProps {
    companyId?: string;
}

const CompanyCompetitors = (props: ComponentProps): JSX.Element => {
    useDocumentTitle('Competitor Analysis');
    const { companyId } = useParams<any>();

    return (
        <ContainerWrapper>
            <Container fullWidth>
                <div className="flex flex-row justify-between my-5">
                    <div className="self-center inline-block text-2xl font-bold align-middle basis-1/2">
                        Competitor Analysis
                    </div>
                </div>
                <div className="grid items-start grid-cols-1 grid-rows-1 gap-10 mb-10 xl:grid-cols-3 auto-rows-auto">
                    <CompetitorCard companyId={companyId} />
                    <CompetitorCard />
                    <CompetitorCard />
                </div>
            </Container>
        </ContainerWrapper>
    );
};

export default CompanyCompetitors;
