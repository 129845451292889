import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import ThemePill from '../../content/themePill';

interface Props {
    document: any;
    currentPage: any;
    setCurrentPage: any;
    currentBackground: any;
    setCurrentBackground: any;
}

interface CarouselProps {
    hires: string;
    thumbnail: string;
    mime: string;
    width: number;
    page: any;
}

const DocumentSlider = ({ document, currentPage, setCurrentPage, currentBackground, setCurrentBackground }: Props) => {
    const onCarouselChange = (index: number, item: any) => {
        setCurrentPage(item.props.page);
        setCurrentBackground(item.props.thumbnail);
    };

    return (
        <div className="flex flex-col w-full">
            <div
                className="transition-all bg-center bg-no-repeat bg-cover"
                style={{ backgroundImage: `url(${currentBackground})` }}
            >
                {document?.['pages']?.length ? (
                    <Carousel
                        showStatus={false}
                        showIndicators={false}
                        swipeable={true}
                        useKeyboardArrows={true}
                        infiniteLoop={false}
                        showThumbs={false}
                        onChange={onCarouselChange}
                        className="w-full pt-5 pb-3 -mb-5 backdrop-filter backdrop-blur-lg backdrop-brightness-90"
                        renderItem={(item: any, options) => {
                            const maxWidth = item?.props?.width > 500 ? '100%' : `${item?.props?.width}px`;
                            return (
                                <img
                                    alt={'Cometrics Result Image'}
                                    src={item.props.hires}
                                    style={{
                                        maxWidth: maxWidth,
                                        margin: '0 auto',
                                        maxHeight: 750,
                                        objectFit: 'contain',
                                    }}
                                />
                            );
                        }}
                    >
                        {document?.pages?.map((assetObject: any) => {
                            if (assetObject?.media_url && assetObject?.thumbnail_url) {
                                const carouselProps: CarouselProps = {
                                    hires: assetObject.media_url,
                                    thumbnail: assetObject.thumbnail_url,
                                    mime: assetObject.mime,
                                    width: assetObject.width,
                                    page: assetObject,
                                };
                                return (
                                    <img
                                        src={carouselProps.thumbnail}
                                        key={carouselProps.thumbnail}
                                        {...carouselProps}
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}
                    </Carousel>
                ) : null}
            </div>
            <div className="background-offset">
                <section className="px-5 mt-10 mb-5">
                    <div className="p-5 bg-white rounded-xl">
                        <div className="flex flex-col xl:flex-row">
                            <div className="w-full xl:w-3/4">
                                <h1 className="text-base font-semibold break-words font-primary 2xl:text-xl">
                                    Summary
                                </h1>
                                <p className="text-sm break-words font-primary 2xl:text-base">
                                    {currentPage?.['summary']}
                                </p>
                            </div>
                            <div className="w-full xl:w-1/4 xl:text-right">
                                <h2 className="text-base font-semibold break-words font-primary 2xl:text-xl pt-3 xl:pt-0">
                                    Page
                                </h2>
                                <p className="text-sm break-words font-primary 2xl:text-base">
                                    {currentPage?.['page']}
                                </p>
                            </div>
                        </div>

                        {currentPage?.['themes']?.length ? (
                            <div className="flex flex-col py-3">
                                <div className="flex flex-row flex-wrap w-full">
                                    {currentPage?.['themes'].map((theme: any) => (
                                        <ThemePill
                                            key={theme.id}
                                            themeName={theme.name}
                                            parentTheme={theme.parent_name}
                                            className="mt-3 mr-3 whitespace-nowrap text-black"
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default DocumentSlider;
