import React from 'react';

const Wrapper = ({ children }: { children: React.ReactNode }): JSX.Element => {
    return (
        <div className="flex items-center justify-center w-full h-full background-offset grow">
            <div className="flex flex-col px-5 py-3 m-5 bg-white rounded-xl 2xl:p-5 max-w-lg">{children}</div>
        </div>
    );
};

export default Wrapper;
