import React, { Dispatch, SetStateAction } from 'react';
import type { Alert } from '../alerts';
import moment from 'moment-timezone';
import Tippy from '@tippyjs/react';
import ManageAlertModal from './manageAlertModal';
import DeleteAlert from './deleteAlert';

interface ComponentProps {
    alert: Alert;
    refreshAlerts: Dispatch<SetStateAction<boolean>>;
}

const AlertItemPill = ({ keyId, name }: { keyId?: React.Key; name: string }): JSX.Element => (
    <span
        key={keyId || name}
        className="px-2 py-1 font-primary tracking-wide font-size-16 font-semibold text-black background-offset rounded-lg text-center capitalize"
    >
        {name}
    </span>
);

const AlertAssociations = ({
    headerText,
    alertItems,
}: {
    headerText: string;
    alertItems: { id: string; name: string }[];
}): JSX.Element => (
    <>
        <h2 className="first:mt-0 mt-5 2xl:mt-5 font-primary font-bold text-lg xl:text-xl text-left pb-1">
            {headerText}
        </h2>
        <div className={`flex flex-row flex-wrap justify-start items-center gap-3`}>
            {alertItems?.map((item: any) => <AlertItemPill key={item.id} keyId={item.id} name={item.name} />)}
        </div>
    </>
);

const AlertCard = (props: ComponentProps): JSX.Element => {
    const { alert, refreshAlerts } = props;

    return (
        <div
            className={`rounded-xl py-5 px-6 bg-white flex flex-col h-full border-t-2 ${
                alert?.disabled ? 'border-vSubtle' : 'border-verdant'
            }`}
        >
            <div className="flex flex-col h-full">
                <div className={`flex-1 ${alert?.disabled ? 'opacity-50' : ''}`}>
                    {alert.keyword && (
                        <AlertAssociations
                            headerText="Keywords"
                            alertItems={[{ id: 'keywords', name: alert?.keyword }]}
                        />
                    )}
                    {alert.countries?.length > 0 && (
                        <AlertAssociations headerText="Countries" alertItems={alert.countries} />
                    )}
                    {alert.companies?.length > 0 && (
                        <AlertAssociations headerText="Companies" alertItems={alert.companies} />
                    )}
                    {alert.media_types?.length > 0 && (
                        <AlertAssociations headerText="Media Types" alertItems={alert.media_types} />
                    )}
                    {alert.themes?.length > 0 && <AlertAssociations headerText="Themes" alertItems={alert?.themes} />}

                    {alert.frequency && (
                        <AlertAssociations
                            headerText="Email Frequency"
                            alertItems={[{ id: 'keywords', name: alert?.frequency }]}
                        />
                    )}
                </div>
                <div>
                    <div className="block mt-8 mb-3 border-t border-vGray"></div>

                    <div className="flex flex-row justify-between items-center w-full text-vSubtle text-opacity-60 font-semibold tracking-wide font-size-16">
                        <Tippy content={moment(alert.created_at).tz(moment.tz.guess()).format('MMM Do, YYYY')}>
                            <p>Created {moment(alert.created_at).tz(moment.tz.guess()).fromNow()}</p>
                        </Tippy>
                        <div className="flex flex-1 justify-end">
                            <ManageAlertModal alert={alert} onClose={() => refreshAlerts(true)}>
                                <button className="flex justify-items-center items-center mr-6 hover:text-verdant">
                                    <i className="far fa-edit"></i>
                                    <p className="text-sm xl:text-base text-center xl:text-right ml-2 font-medium">
                                        Edit
                                    </p>
                                </button>
                            </ManageAlertModal>

                            <DeleteAlert alert={alert} refreshAlerts={() => refreshAlerts(true)}>
                                <button className="flex justify-items-center items-center hover:text-red-500">
                                    <i className="far fa-trash-can-xmark"></i>
                                    <p className="text-sm xl:text-base text-center xl:text-right inline ml-1">Remove</p>
                                </button>
                            </DeleteAlert>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AlertCard;
