import React, { useEffect, useState } from 'react';
import ApiClient from '~/utils/apiClient';
import Spinner from '~/components/spinner';
import ReactApexChart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import { useSearchUrls } from '~/hooks/useSearchUrls';

interface ComponentProps {
    companyId: number;
    title?: string;
    companyName?: string;
}

const ThemeTreeMap = (props: ComponentProps): JSX.Element => {
    const { companyId, title, companyName } = props;

    const [chartData, setChartData] = useState<any>();
    const [companyNameState, setCompanyNameState] = useState<string>(companyName || '');
    const { generateSearchByThemeAndCompanyUrl } = useSearchUrls();

    useEffect(() => {
        if (companyName) {
            setCompanyNameState(companyName);
        }
    }, [companyName]);

    const options: ApexOptions = {
        colors: ['#3B82F6', '#F59E0B', '#D946EF'],

        legend: {
            show: true,
            fontSize: '16px',
            fontFamily: 'cmFont, AvenirLight, Arial',
            fontWeight: 700,

            markers: {
                width: 22,
                height: 2.5,
                radius: 10,
                offsetX: -3,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 10,
            },
        },
        chart: {
            height: 550,
            type: 'treemap',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    const { dataPointIndex, seriesIndex } = config;
                    const chartConfig = config?.w?.config;
                    const series = chartConfig.series?.[seriesIndex];
                    const themeLabel = series?.data?.[dataPointIndex]?.x;

                    if (themeLabel && companyNameState) {
                        try {
                            const searchUrl = generateSearchByThemeAndCompanyUrl(themeLabel, companyNameState);
                            window.location.assign(searchUrl);
                        } catch (e) {
                            console.error(e);
                        }
                    }
                },
            },
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '20px',
                fontFamily: 'cmFont, AvenirLight, Arial',
                fontWeight: 700,
            },
        },
        title: {
            text: '',
            align: 'center',
        },
    };

    const getChartData = async () => {
        setChartData(undefined);
        const data = await ApiClient.get(`/api/stats/theme-tree-map?company_id=${companyId}`);

        if (data && !data?.error) {
            setChartData(data);
        }
    };

    useEffect(() => {
        getChartData();
    }, []);

    useEffect(() => {
        if (companyId) {
            getChartData();
        }
    }, [companyId]);

    return (
        <>
            {title && <h2 className="mt-5 mb-3 text-2xl font-bold">{title}</h2>}
            <div className="w-full h-full p-5 bg-white rounded-xl">
                {chartData ? (
                    <ReactApexChart options={options} series={chartData} type="treemap" height={550} />
                ) : (
                    <Spinner></Spinner>
                )}
            </div>
        </>
    );
};

export default ThemeTreeMap;
