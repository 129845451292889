import React, { useState, useEffect } from 'react';
import Spinner from '~/components/spinner';

import accentByParentTheme from '~/components/themeAccents';
import ApiClient from '~/utils/apiClient';
import { useSearchUrls } from '~/hooks/useSearchUrls';

interface ThemeProps {
    companyId?: string;
    company_page?: boolean;
}

const TrendingThemes = (ThemeProps: ThemeProps): JSX.Element => {
    const { companyId, company_page } = ThemeProps;
    const { generateSearchByThemeUrl } = useSearchUrls();
    const trendingThemeParents = ['Environmental', 'Social', 'Governance'];
    const [trendingThemes, setTrendingThemes] = useState<ThemeCategory[]>();

    const getTrendingThemes = async (): Promise<void> => {
        setTrendingThemes(undefined);
        if (companyId) {
            setTrendingThemes(await ApiClient.get(`/api/stats/trending-theme-changes?company_id=${companyId}`));
        } else {
            setTrendingThemes(await ApiClient.get(`/api/stats/global-trending-themes`));
        }
    };

    useEffect(() => {
        getTrendingThemes();
    }, [companyId]);

    return (
        <div className="flex flex-col p-5 mb-5 bg-white rounded-xl 2xl:mb-10">
            <div className={` ${company_page ? ' hidden ' : ' '} flex flex-row justify-between items-center mb-3`}>
                <h1 className="text-base font-semibold text-black font-primary 2xl:text-lg">Trending Themes</h1>
                <p className="text-sm tracking-wider text-right 2xl:font-size-16 font-primary text-vSubtle">
                    Past 30 days
                </p>
            </div>
            {trendingThemes ? (
                <div
                    className={`${
                        company_page ? ' xl:flex-col ' : ' xl:space-x-3 '
                    } flex flex-col xl:flex-row  justify-between mb-3 xl:mb-0`}
                >
                    {trendingThemeParents.map((parentThemeName: string) => {
                        const themeColor = accentByParentTheme(parentThemeName, true);
                        return (
                            <div className="flex flex-col" key={parentThemeName}>
                                <h3 className="mb-3 text-lg font-bold text-black capitalize font-primary">
                                    <i className={`fas fa-horizontal-rule mr-2 ${themeColor}`}></i>
                                    {parentThemeName}
                                </h3>
                                {trendingThemes[parentThemeName].length ? (
                                    trendingThemes[parentThemeName].map((change: any, index: number) => (
                                        <div className="flex flex-row mb-3" key={change.theme_name}>
                                            <a
                                                className="text-sm text-black font-primary hover:text-verdant"
                                                href={generateSearchByThemeUrl(change.theme_name)}
                                            >
                                                <span className="mr-2 font-semibold">
                                                    {parentThemeName[0]}
                                                    {index + 1}
                                                </span>
                                                {change.theme_name}
                                                {!!Number(change.log_count_change) &&
                                                    parseInt(change.log_count_change) < 0 && (
                                                        <span className="pl-2 text-red-500">
                                                            <i className="pr-1 fas fa-caret-down"></i>
                                                            {Math.round(Number(change.log_count_change))}%
                                                        </span>
                                                    )}
                                                {String(change.log_count_change) &&
                                                    change.log_count_change == 'new' && (
                                                        <span className="text-xs font-semibold rounded-xl bg-verdant text-white ml-2 px-2 py-0.5">
                                                            {change.log_count_change.toUpperCase()}
                                                        </span>
                                                    )}
                                                {!!Number(change.log_count_change) &&
                                                    parseInt(change.log_count_change) > 0 && (
                                                        <span className="pl-2 text-verdant">
                                                            <i className="pr-1 fas fa-caret-up"></i>
                                                            {Math.round(Number(change.log_count_change))}%
                                                        </span>
                                                    )}
                                                {!change.log_count_change && (
                                                    <span className="pl-2 font-semibold text-black">-</span>
                                                )}
                                            </a>
                                        </div>
                                    ))
                                ) : (
                                    <p className="mb-1 text-sm font-semibold text-black font-primary">No results</p>
                                )}
                            </div>
                        );
                    })}
                </div>
            ) : (
                <Spinner />
            )}
        </div>
    );
};

export default TrendingThemes;
