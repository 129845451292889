import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Api from '~/utils/apiClient';
import SearchProvider from '../search/search';
import { Container, ContainerWrapper } from '../layout/container';
import Sidebar from '../layout/sidebar';
import Spinner from '~/components/spinner';

const SavedContent = (): JSX.Element => {
    const { saveGroupId } = useParams<any>();
    const [contentIdFilter, setContentIdFilter] = useState<string>();
    const [saveGroup, setSaveGroup] = useState<any>();

    const getContentIds = async () => {
        const response = await Api.get(`/api/save-groups/${saveGroupId}/`);
        setSaveGroup(response);
        if (response?.content_ids) {
            let filterString = '';
            for (const [index, id] of response.content_ids.entries()) {
                filterString += `uuid:${id}`;
                if (index < response.content_ids.length - 1) {
                    filterString += ' OR ';
                }
            }
            setContentIdFilter(filterString);
        }
    };

    useEffect(() => {
        getContentIds();
    }, []);

    if (contentIdFilter && saveGroup) {
        return <SearchProvider filter={contentIdFilter} title={saveGroup.name} hideClearFilter />;
    } else {
        return (
            <ContainerWrapper>
                <Sidebar header="Search Filters">
                    <></>
                </Sidebar>
                <Container>
                    {contentIdFilter === '' && <h1>No content saved yet</h1>}
                    {!contentIdFilter && !saveGroup && <Spinner />}
                </Container>
            </ContainerWrapper>
        );
    }
};

export default SavedContent;
