import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { Formik, Field, Form, FormikHelpers } from 'formik';

import Wrapper from '../components/wrapper';
import Header from '../components/header';

import Api from '~/utils/apiClient';
import useDocumentTitle from '~/hooks/useDocumentTitle';

interface FormInputs {
    first_name: string;
    last_name: string;
    executive_title: string;
    company_name: string;
    linkedin_url: string;
    twitter_url: string;
    threads_url: string;
    instagram_url: string;
    additional_details: string;
}

const Executives = (): JSX.Element => {
    useDocumentTitle('Nominate an Executive');
    const [executiveName, setExecutiveName] = useState<string>('');
    return (
        <Wrapper>
            <Header
                title="Nominate an Executive"
                description="If you would like to nominate an Executive or Board Member for inclusion in the ISSUTRKR database, please submit the following fields:"
            />
            <Formik
                initialValues={{
                    first_name: '',
                    last_name: '',
                    executive_title: '',
                    company_name: '',
                    linkedin_url: '',
                    twitter_url: '',
                    threads_url: '',
                    instagram_url: '',
                    additional_details: '',
                }}
                onSubmit={async (values: FormInputs, { setSubmitting, resetForm }: FormikHelpers<FormInputs>) => {
                    const full_name = `${values.first_name} ${values.last_name}` || values.company_name;
                    const response = await Api.create('/api/nominations', {
                        nomination: {
                            company_name: values.company_name || full_name,
                            additional_details: values.additional_details,
                            details: { ...values },
                        },
                    });
                    if (response && !response.error) {
                        setExecutiveName(full_name);
                        toast.success('Your executive nomination has been received!');
                        setSubmitting(false);
                        resetForm();
                    } else {
                        toast.error('There was a problem submitting your nomination.');
                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <Field
                            id="first_name"
                            name="first_name"
                            placeholder="First Name"
                            className="w-full p-2 text-lg cm-input"
                            required
                        />
                        <Field
                            id="last_name"
                            name="last_name"
                            placeholder="Last Name"
                            className="w-full p-2 mt-5 text-lg cm-input"
                            required
                        />
                        <Field
                            id="executive_title"
                            name="executive_title"
                            placeholder="Executive Title"
                            className="w-full p-2 mt-5 text-lg cm-input"
                            required
                        />
                        <Field
                            id="company_name"
                            name="company_name"
                            placeholder="Company Name"
                            className="w-full p-2 mt-5 text-lg cm-input"
                            required
                        />
                        <Field
                            id="linkedin_url"
                            name="linkedin_url"
                            placeholder="LinkedIn URL"
                            className="w-full p-2 mt-5 text-lg cm-input"
                        />
                        <Field
                            id="twitter_url"
                            name="twitter_url"
                            placeholder="X / Twitter URL"
                            className="w-full p-2 mt-5 text-lg cm-input"
                        />
                        <Field
                            id="threads_url"
                            name="threads_url"
                            placeholder="Threads URL"
                            className="w-full p-2 mt-5 text-lg cm-input"
                        />
                        <Field
                            id="instagram_url"
                            name="instagram_url"
                            placeholder="Instagram URL"
                            className="w-full p-2 mt-5 text-lg cm-input"
                        />
                        <Field
                            id="additional_details"
                            name="additional_details"
                            placeholder="Any other details you would like to share or any other social media links"
                            as="textarea"
                            rows="10"
                            className="w-full p-2 mt-5 text-lg cm-input"
                        />

                        <button type="submit" className="mt-5 text-lg btn-primary 2xl:text-xl" disabled={isSubmitting}>
                            Submit Nomination
                        </button>

                        <div
                            className={`mt-5 w-full bg-verdant text-center rounded-2xl py-2 px-1 ${
                                executiveName ? '' : 'hidden'
                            }`}
                        >
                            <p className="text-lg text-white font-primary">
                                <i className="mr-3 far fa-check"></i> We&apos;ve received your nomination for{' '}
                                {executiveName}! If your executive is added to our database, we will email you with
                                details on how to subscribe for access.
                            </p>
                        </div>
                    </Form>
                )}
            </Formik>
        </Wrapper>
    );
};

export default Executives;
