import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Executive from './executive';
import Executives from './executives';

export const ExecutiveRoutes = () => {
    return (
        <Routes>
            <Route path=":executiveId" element={<Executive />} />
            <Route path="/" element={<Executives />} />
            <Route path="*" element={<Navigate to="." />} />
        </Routes>
    );
};
