import React from 'react';
import { useAuth } from '~/hooks/useAuth';

export const ProtectedRoute = ({ children }: { children: any }) => {
    const { isAuthenticated } = useAuth();

    if (isAuthenticated === true) {
        return children;
    } else {
        window.location.replace('/users/login');
    }
};

export default ProtectedRoute;
