import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import ApiClient from '~/utils/apiClient';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import Spinner from '~/components/spinner';

interface ComponentProps {
    title?: string;
    parentThemeName?: string;
}

const TopThemeLineChart = (props: ComponentProps): JSX.Element => {
    const { title, parentThemeName } = props;
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

    const options = {
        responsive: true,

        elements: {
            point: {
                radius: 0,
            },
        },
        plugins: {
            legend: {
                labels: {
                    font: {
                        family: "'AvenirLight', 'Montserrat', sans-serif" as const,
                    },
                },
                position: 'top' as const,
            },
            title: {
                display: false,
                text: '',
            },
        },
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
    };
    const [chartData, setChartData] = useState<any>();

    const getChartData = async () => {
        const data = await ApiClient.get(
            `/api/stats/top-themes-sparklines${parentThemeName?.length ? `?root_theme=${parentThemeName}` : ''}`,
        );
        if (data && !data?.error) {
            const dataSets = {
                labels: data?.[0]?.labels,
                datasets: data,
            };
            setChartData(dataSets);
        }
    };

    useEffect(() => {
        getChartData();
    }, []);

    return (
        <div className="rounded-xl py-5 xl:px-5 bg-white flex flex-col mb-5 2xl:mb-10 w-full">
            <div className="flex flex-col justify-between items-center">
                <div className="flex flex-row justify-between items-center mb-3 w-full px-5 xl:px-0">
                    <h1 className="font-primary font-semibold text-base 2xl:text-lg text-black">
                        {title ? title : 'Trending Themes'}
                    </h1>
                    <p className="text-right text-sm 2xl:font-size-16 font-primary text-vSubtle tracking-wider">
                        Past 30 days
                    </p>
                </div>
                {chartData ? <Line options={options} data={chartData} style={{ minHeight: 200 }} /> : <Spinner />}
            </div>
        </div>
    );
};

export default TopThemeLineChart;
