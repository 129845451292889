import React, { useState } from 'react';
import Spinner from '~/components/spinner';
import ApiClient, { showNotificationAfterApiCall } from '~/utils/apiClient';
import { useHits, UseHitsProps } from 'react-instantsearch';
import downloadBlob from '~/utils/downloadBlob';

interface ComponentProps extends UseHitsProps {
    children: React.ReactNode;
}

const ExportSearchPage = (props: ComponentProps): JSX.Element => {
    const { children } = props;
    const { hits, results } = useHits(props);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const downloadZippedContent = async (): Promise<void> => {
        setIsLoading(true);
        const response = await ApiClient.export(`/api/exports`, {
            export: { content_uuids: hits.map((hit: any) => hit.uuid), media: true, file_type: 'csv' },
        });
        showNotificationAfterApiCall(response, 'Success! Your content export started downloading');
        if (!response?.error) {
            downloadBlob(response, `cometrics-${results?.queryID}-${Date.now()}.zip`);
        }
        setIsLoading(false);
    };

    if (hits?.length > 0 && hits?.length <= 50) {
        return (
            <div
                onClick={() => {
                    if (!isLoading) {
                        downloadZippedContent();
                    }
                }}
                className={`${isLoading ? 'disabled opacity-50 ' : ''}flex flex-row justify-between items-center`}
            >
                {children}
                {isLoading ? (
                    <span>
                        {' '}
                        <Spinner className={'justify-end items-center'} height={20} width={20} />
                    </span>
                ) : null}
            </div>
        );
    } else {
        return <></>;
    }
};

export default ExportSearchPage;
