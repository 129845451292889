import React, { useEffect, useState } from 'react';
import ApiClient from '~/utils/apiClient';
import moment from 'moment-timezone';
import Tippy from '@tippyjs/react';
import { ErrorBoundary } from 'react-error-boundary';
import { Link } from 'react-router-dom';
import { ThemeBar, ThemeBarProps } from '~/components/themeBar';

interface ComponentProps {
    companyId: string;
}

type DocType = 'pdf' | 'excel' | 'powerpoint' | 'word' | 'financial' | 'zip';

interface Document {
    id: number;
    uuid: string;
    display_name: string;
    description?: string;
    visible: boolean;
    media_url?: string;
    thumbnail_url?: string;
    created_at: string;
    doc_type: DocType;
    themes?: any;
    summary?: string;
    theme_stats?: ThemeBarProps['themeStats'];
}

const CompanyDocuments = (props: ComponentProps): JSX.Element => {
    const { companyId } = props;
    const [documents, setDocuments] = useState<Document[]>();
    const getDocuments = async () => {
        const documentResponse = await ApiClient.get(`/api/companies/${companyId}/documents`);
        setDocuments(documentResponse);
    };

    useEffect(() => {
        getDocuments();
    }, []);

    useEffect(() => {
        getDocuments();
    }, [companyId]);

    const docTypeToIcon = (docType: DocType): string => {
        const docTypeIconMap: Record<DocType, string> = {
            pdf: 'fa-light fa-file-pdf',
            excel: 'fa-light fa-file-excel',
            powerpoint: 'fa-light fa-file-powerpoint',
            word: 'fa-light fa-file-word',
            financial: 'fa-light fa-file-chart-column',
            zip: 'fa-light fa-file-zipper',
        };
        return docTypeIconMap[docType];
    };

    if (documents?.length) {
        return (
            <div className="flex flex-col background-offset mb-5 w-full">
                <h2 className="font-primary font-bold text-2xl text-black w-full my-5">Documents</h2>
                <div className="grid grid-flow-row grid-cols-1 xl:grid-cols-3 gap-5 xl:gap-10 auto-rows-max">
                    {documents.map(doc => (
                        <Link
                            to={`/app/company/${companyId}/document/${doc.uuid}`}
                            target="_blank"
                            rel="noreferrer"
                            className="flex flex-col bg-white border-t-2 border-white rounded-2xl w-full p-5 hover:cursor hover:border-t-2 hover:border-verdant"
                            key={doc.uuid}
                        >
                            {doc.thumbnail_url ? (
                                <img
                                    src={doc.thumbnail_url}
                                    alt={doc.display_name}
                                    className="w-full h-40 object-cover rounded-xl mb-3"
                                />
                            ) : null}

                            <h1 className="font-semibold text-lg">{doc.display_name}</h1>
                            {doc.description ? (
                                <p className="grow">{doc.description}</p>
                            ) : (
                                <p className="grow">{doc.summary}</p>
                            )}

                            {doc.theme_stats ? (
                                <ErrorBoundary fallback={<></>}>
                                    <ThemeBar themeStats={doc.theme_stats} className="mt-3 mb-1" />
                                </ErrorBoundary>
                            ) : null}

                            <div className="flex flex-row justify-between items-center text-sm text-gray-300 -mb-2 mt-2">
                                <Tippy content={moment(doc.created_at).tz(moment.tz.guess()).format('MMM Do, YYYY')}>
                                    <p className="text-gray-400">
                                        Added {moment(doc.created_at).tz(moment.tz.guess()).fromNow()}
                                    </p>
                                </Tippy>
                                <div className="flex flex-row justify-end items-center">
                                    <i className={`${docTypeToIcon(doc.doc_type)} `}></i>
                                    <span className="ml-2 capitalize">{doc.doc_type}</span>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default CompanyDocuments;
