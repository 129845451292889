import React from 'react';

interface ComponentProps {
    team: any;
}

const TeamDetails = (props: ComponentProps): JSX.Element => {
    const { team } = props;

    return (
        <div className="flex flex-col xl:flex-row justify-between mb-5 space-y-3 xl:space-y-0 xl:space-x-5">
            {/* <div className="rounded-xl px-5 py-3 2xl:p-5 bg-white grow">
                <div className="flex flex-col justify-center items-center">
                    {team?.logo?.length && (
                        <img src={team?.logo} alt="Team Logo" style={{ maxWidth: 225 }} className="h-auto" />
                    )}
                    <h3 className="font-primary text-lg 2xl:text-xl text-black mt-1">{team.name}</h3>
                </div>
            </div> */}
            <div className="rounded-xl px-5 py-3 2xl:p-5 bg-white grow">
                <div className="flex flex-col justify-center items-center h-full">
                    <div className="grow flex items-center align-middle">
                        <h2 className="font-primary text-5xl">{team.active_users_count}</h2>
                    </div>
                    <h3 className="font-primary text-lg 2xl:text-xl text-black mt-1">Active Users</h3>
                </div>
            </div>
            <div className="rounded-xl px-5 py-3 2xl:p-5 bg-white grow">
                <div className="flex flex-col justify-center items-center h-full">
                    <div className="grow flex items-center align-middle">
                        <h2 className="font-primary text-5xl">{team.seat_count}</h2>
                    </div>
                    <h3 className="font-primary text-lg 2xl:text-xl text-black mt-1">Seats Available</h3>
                </div>
            </div>
            {/* <div className="rounded-xl px-5 py-3 2xl:p-5 bg-white grow">
                <div className="flex flex-col justify-center items-center h-full">
                    <div className="grow flex items-center align-middle">
                        <h2
                            className={`font-primary text-5xl capitalize ${
                                team.subscription_status === 'active' ? 'text-verdant' : 'text-black'
                            }`}
                        >
                            {team.subscription_status}
                        </h2>
                    </div>
                    <h3 className="font-primary text-lg 2xl:text-xl text-black mt-1">Subscription Status</h3>
                </div>
            </div> */}
        </div>
    );
};

export default TeamDetails;
