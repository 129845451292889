import React from 'react';
import { useExecutive } from '../api/getExecutive';
import { useParams } from 'react-router-dom';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '~/components/ui/hover-card';

const OverviewHeader = (): JSX.Element => {
    const { executiveId } = useParams<string>();
    if (!executiveId) return <></>;
    const { executive } = useExecutive(executiveId);

    if (!executive) {
        return <></>;
    }
    return (
        <>
            <h1>{executive.name}</h1>
            <p>
                {executive.summary}{' '}
                <HoverCard>
                    <HoverCardTrigger className="text-gray-500">
                        <i className="far fa-info-circle hover:text-cometrics"></i>
                    </HoverCardTrigger>
                    <HoverCardContent className="w-96 border-cometrics">
                        <p>
                            This Executive Summary was created using the latest updates from the executive&apos;s social
                            media activity, LinkedIn posts, and references within corporate documents, including press
                            releases, sustainability reports, company websites, and Analyst Calls.
                        </p>
                    </HoverCardContent>
                </HoverCard>
            </p>
        </>
    );
};

export default OverviewHeader;
