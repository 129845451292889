import React, { useEffect, useState } from 'react';
import ThemePillWithoutLink from '~/components/themePillWithoutLink';

interface ComponentProps {
    themes: any;
    selectedThemes: any;
    setSelectedThemes: any;
}

const DocumentThemes = ({ themes, selectedThemes, setSelectedThemes }: ComponentProps): JSX.Element => {
    const [environmentalThemes, setEnvironmentalThemes] = useState<any[]>();
    const [socialThemes, setSocialThemes] = useState<any[]>();
    const [governanceThemes, setGovernanceThemes] = useState<any[]>();

    useEffect(() => {
        if (themes?.length) {
            const environmentalThemes = filterAndSortThemes(themes, 'Environmental');
            const socialThemes = filterAndSortThemes(themes, 'Social');
            const governanceThemes = filterAndSortThemes(themes, 'Governance');

            setEnvironmentalThemes(environmentalThemes);
            setSocialThemes(socialThemes);
            setGovernanceThemes(governanceThemes);
        }
    }, [themes]);

    const filterAndSortThemes = (themes: any[], parentTheme: string) => {
        return themes
            .filter((theme: any) => theme.parent_name === parentTheme)
            ?.sort((a: any, b: any) => a['name'].localeCompare(b['name']));
    };

    const toggleSelectedTheme = (themeId: number) => {
        if (selectedThemes?.includes(themeId)) {
            setSelectedThemes(selectedThemes.filter((theme: number) => theme !== themeId));
        } else {
            setSelectedThemes([...selectedThemes, themeId]);
        }
    };

    return (
        <div>
            <p className="w-full font-semibold text-black font-primary">Environment Themes</p>
            {environmentalThemes?.length ? (
                <div className="flex flex-row flex-wrap w-full">
                    {environmentalThemes.map((theme: any) => (
                        <div
                            key={theme.id}
                            className="mt-3 mr-3 whitespace-nowrap text-black"
                            onClick={() => toggleSelectedTheme(theme.id)}
                        >
                            <ThemePillWithoutLink
                                themeName={theme.name}
                                parentTheme={theme.parent_name}
                                className={`cursor-pointer border-2 ${
                                    selectedThemes?.includes(theme.id)
                                        ? 'border-2 border-verdant'
                                        : 'border-transparent'
                                }`}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <p>No Environmental Themes</p>
            )}

            <p className="w-full font-semibold text-black font-primary mt-5 pt-3">Social Themes</p>
            {socialThemes?.length ? (
                <div className="flex flex-row flex-wrap w-full">
                    {socialThemes.map((theme: any) => (
                        <div
                            key={theme.id}
                            className="mt-3 mr-3 whitespace-nowrap text-black"
                            onClick={() => toggleSelectedTheme(theme.id)}
                        >
                            <ThemePillWithoutLink
                                themeName={theme.name}
                                parentTheme={theme.parent_name}
                                className={`cursor-pointer border-2 ${
                                    selectedThemes?.includes(theme.id) ? ' border-verdant' : 'border-transparent'
                                }`}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <p>No Social Themes</p>
            )}

            <p className="w-full font-semibold text-black font-primary mt-5 pt-3">Governance Themes</p>
            {governanceThemes?.length ? (
                <div className="flex flex-row flex-wrap w-full">
                    {governanceThemes.map((theme: any) => (
                        <div
                            key={theme.id}
                            className="mt-3 mr-3 whitespace-nowrap text-black"
                            onClick={() => toggleSelectedTheme(theme.id)}
                        >
                            <ThemePillWithoutLink
                                themeName={theme.name}
                                parentTheme={theme.parent_name}
                                className={`cursor-pointer border-2 ${
                                    selectedThemes?.includes(theme.id) ? 'border-verdant' : 'border-transparent'
                                }`}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <p>No Governance Themes</p>
            )}
        </div>
    );
};

export default DocumentThemes;
