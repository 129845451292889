import useSWR from 'swr';
import ApiClient from '~/utils/apiClient';

export const getTopThemes = async (url: string) => {
    const response = await ApiClient.get(url);
    if (response.error) {
        throw new Error(response.error);
    }
    return response;
};

export const useTopThemes = (executiveId: string) => {
    const { data, error, isLoading } = useSWR<any>(`/api/executives/${executiveId}/top-themes`, getTopThemes);

    return {
        themes: data,
        isLoading,
        isError: error,
    };
};
