import ApiClient from '~/utils/apiClient';

export const createCheckoutSession = async (selectedPlan: string) => {
    const response = await ApiClient.create('/api/subscription', {
        subscription: { price_id: selectedPlan, quantity: 1 },
    });
    if (response.error) {
        throw new Error(response.error);
    }
    return response;
};
