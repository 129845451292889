import React from 'react';

import SkeletonCard from '~/components/skeletonCard';

import { useFeaturedExecutive } from '../api/getFeaturedExecutive';
import { Card, CardContent, CardDescription } from '~/components/ui/card';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '~/components/ui/hover-card';
import { NavLink } from 'react-router-dom';

const ExecutiveBioAndThumbnail = () => {
    const { executive, isLoading } = useFeaturedExecutive('michael-miebach');

    if (isLoading) {
        return <SkeletonCard />;
    }
    if (!executive) {
        return <></>;
    }
    return (
        <Card className="border-0 shadow-none">
            <CardContent className="pt-6">
                <div className="flex">
                    <img
                        alt="Executive Thumbnail"
                        src={executive.profile_thumbnail_url}
                        className="bg-white rounded-xl w-full max-w-[150px] object-cover aspect-square"
                    />
                    <div className="ml-5">
                        <NavLink to={`/app/executives/${executive.route}`}>
                            <h2>{executive.name}</h2>
                            <CardDescription>
                                {executive.title} @ {executive.company.name}
                            </CardDescription>
                        </NavLink>
                        <p className="pt-3">
                            {executive.summary}{' '}
                            <HoverCard>
                                <HoverCardTrigger className="text-gray-500">
                                    <i className="far fa-info-circle hover:text-cometrics"></i>
                                </HoverCardTrigger>
                                <HoverCardContent className="w-96 border-cometrics">
                                    <p>
                                        This Executive Summary was created using the latest updates from the
                                        executive&apos;s social media activity, LinkedIn posts, and references within
                                        corporate documents, including press releases, sustainability reports, company
                                        websites, and Analyst Calls.
                                    </p>
                                </HoverCardContent>
                            </HoverCard>
                        </p>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default ExecutiveBioAndThumbnail;
