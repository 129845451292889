import useSWR from 'swr';
import ApiClient from '~/utils/apiClient';
import { TimeSeriesCount } from '../types';

export const getThemeCountBySector = async (url: string) => {
    const response = await ApiClient.get(url);
    if (response.error) {
        throw new Error(response.error);
    }
    return response;
};

export const useThemeSectorTrends = (themeName: string) => {
    const { data, error, isLoading } = useSWR<TimeSeriesCount[]>(
        `/api/stats/executive-theme-sector-counts?theme_name=${themeName}`,
        getThemeCountBySector,
    );

    return {
        trends: data,
        isLoading,
        isError: error,
    };
};
