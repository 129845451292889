import Tippy from '@tippyjs/react';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ApiClient from '~/utils/apiClient';
import RootThemesDonutChart from '../../visualization/rootThemesDonutChart';
import TrendingThemes from '../../visualization/trendingThemes';
import WeeklyRootThemeCount from '../../visualization/weeklyRootThemeCount';
import CompanySelectModal from '../components/companySelectModal';
import ThemeTreeMap from './themeTreeMap';

interface ComponentProps {
    companyId?: string;
}

const CompetitorCard = (props: ComponentProps): JSX.Element => {
    const { companyId } = props;
    const [selectedCompanyId, setSelectedCompanyId] = useState<any>();
    const [company, setCompany] = useState<any>();

    const getCompany = async (companyId?: string) => {
        setCompany(null);
        if (companyId) {
            setCompany(await ApiClient.get(`/api/companies/${companyId}`));
        }
    };

    useEffect(() => {
        if (companyId) {
            getCompany(companyId);
        } else {
            getCompany();
        }
    }, []);

    useEffect(() => {
        if (selectedCompanyId) {
            getCompany(selectedCompanyId);
        }
    }, [selectedCompanyId]);

    const CompanyModalSelect = (props: any) => {
        return (
            <CompanySelectModal
                handleSelectedOption={(option: string) => {
                    getCompany(option);
                    setSelectedCompanyId(option);
                }}
            >
                {props.children}
            </CompanySelectModal>
        );
    };

    return (
        <div className="flex flex-col last:mb-10 justify-items-stretch">
            <div className="relative flex flex-col h-auto p-5 bg-white rounded-3xl">
                {!company?.id && (
                    <div className="flex items-center justify-center h-full grow">
                        <CompanyModalSelect>
                            <div className="flex flex-col items-center justify-center h-full grow">
                                <h2 className="mb-3 text-lg text-black font-primary">Select company to compare with</h2>
                                <button className="btn-primary">Select Company</button>
                            </div>
                        </CompanyModalSelect>
                    </div>
                )}

                {company?.id && (
                    <div className="flex flex-col items-stretch justify-center flex-auto mb-10 group">
                        <div className="absolute top-0 right-0 p-5 text-xl text-gray-300 transition-all rounded-full group-hover:text-black hover:text-white hover:cursor-pointer">
                            <CompanyModalSelect>
                                <i className="fa-solid fa-ellipsis-stroke-vertical"></i>
                            </CompanyModalSelect>
                        </div>
                        {company?.logo_url && (
                            <div className="flex flex-col items-center justify-center h-40 mt-10">
                                <Tippy content={company.name}>
                                    <NavLink to={`/app/company/${company.id}`}>
                                        <img
                                            alt={'company logo'}
                                            src={company?.logo_url}
                                            className="h-auto max-w-full mx-auto bg-white rounded-2xl xl:max-w-xs max-h-40"
                                        />
                                    </NavLink>
                                </Tippy>
                            </div>
                        )}
                        <h1 className="w-full mt-10 mb-5 text-2xl font-bold text-center text-black font-primary">
                            {company.name}
                        </h1>

                        <RootThemesDonutChart companyId={company.id} companyPage={true} />
                    </div>
                )}
            </div>
            {company?.id && (
                <>
                    <div className="mt-10">
                        <WeeklyRootThemeCount companyId={company.id} />
                    </div>

                    <ThemeTreeMap companyId={company.id} />

                    <div className="flex flex-col items-stretch flex-auto p-5 mt-10 bg-white rounded-3xl">
                        <div className={`text-right basis-1/2 inline-block align-middle font-light italic z-50 -mb-10`}>
                            Past 30 days
                        </div>
                        <TrendingThemes company_page={true} companyId={company.id} />
                    </div>
                </>
            )}
        </div>
    );
};

export default CompetitorCard;
