import React, { useEffect, useState } from 'react';
import ApiClient from '~/utils/apiClient';
import { Link } from 'react-router-dom';

interface ComponentProps {
    companyId: string;
}

interface Executive {
    uuid: string;
    name: string;
    biography?: string;
    visible: boolean;
    learn_more_link: string;
    position: string;
    profile_photo_url?: string;
    profile_thumbnail_url?: string;
    route: string;
    created_at: string;
    title: string;
    company_name: string;
}

const CompanyExecutives = (props: ComponentProps): JSX.Element => {
    const { companyId } = props;
    const [executives, setExecutives] = useState<Executive[]>();

    const getExecutives = async () => {
        const execResponse = await ApiClient.get(`/api/companies/${companyId}/executives`);
        setExecutives(execResponse);
    };

    useEffect(() => {
        getExecutives();
    }, []);

    useEffect(() => {
        getExecutives();
    }, [companyId]);

    if (executives?.length) {
        return (
            <div className="flex flex-col background-offset mb-5 w-full">
                <h2 className="font-primary font-bold text-2xl text-black w-full my-5">Executives</h2>
                <div className="grid grid-flow-row grid-cols-1 xl:grid-cols-3 gap-5 xl:gap-10 auto-rows-max">
                    {executives.map(executive => (
                        <Link
                            to={`/app/executives/${executive.route}`}
                            target="_blank"
                            rel="noreferrer"
                            className="flex flex-col bg-white border-t-2 border-white rounded-2xl w-full p-5 hover:cursor hover:border-t-2 hover:border-verdant"
                            key={executive.route}
                        >
                            {executive.profile_thumbnail_url ? (
                                <img
                                    src={executive.profile_thumbnail_url}
                                    alt={executive.name}
                                    className="w-40 h-40 object-cover rounded-full mx-auto mb-3"
                                />
                            ) : null}
                            <h1 className="font-semibold text-xl text-center">{executive.name}</h1>
                            <p className="font-semibold text-base italic text-gray-500 text-center">
                                {executive.title} {executive.position ? `- ${executive.position}` : ''}
                            </p>
                            {executive.biography ? <p className="grow">{executive.biography}</p> : null}
                        </Link>
                    ))}
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default CompanyExecutives;
