import React from 'react';

const Footer = () => {
    return (
        <footer
            style={{ textAlign: 'center' }}
            className="flex flex-col items-center justify-between px-12 py-5 border-t border-gray-200 xl:flex-row font-primary"
        >
            <div className="flex flex-wrap justify-center sm:justify-start">
                <h3 className="w-full mr-3 sm:w-auto">Need help?</h3>

                <span className="mr-3">
                    <i className="mr-1 opacity-50 far fa-envelope text-verdant"></i>
                    <a href="mailto:info@cometrics.io" className="hover:text-verdant">
                        info@cometrics.io
                    </a>
                </span>
            </div>

            <div className="flex flex-wrap my-3 xl:my-0">
                <a href="https://www.cometrics.io/about-us" className="text-verdant hover:text-green-700">
                    About Us
                </a>
                <span className="mx-1">-</span>
                <a href="https://www.cometrics.io/terms-of-service" className="text-verdant hover:text-green-700">
                    Terms of Service
                </a>
                <span className="mx-1">-</span>
                <a href="https://www.cometrics.io/privacy-policy" className="text-verdant hover:text-green-700">
                    Privacy Policy
                </a>
            </div>
            <p>&copy;{` ${new Date().getFullYear()}`} Verdant Networks Inc. All Rights Reserved.</p>
        </footer>
    );
};

export default Footer;
