import React from 'react';
import { ContainerWrapper } from '../layout/container';
import PlatformPolarAreaChart from './platformPolarAreaChart';
import RootThemesDonutChart from './rootThemesDonutChart';
import TopThemeLineChart from './topThemesLineChart';

const DashboardHome = (): JSX.Element => {
    return (
        <ContainerWrapper className="background-offset px-3 xl:px-0">
            <div className="flex flex-col xl:flex-row mx-5 my-5 xl:space-x-5 xl:container xl:mx-auto 2xl:mt-10 2xl:space-x-10">
                <div className="w-full xl:w-3/4">
                    <TopThemeLineChart parentThemeName="environmental" title="Top Environmental Themes" />
                    <TopThemeLineChart parentThemeName="social" title="Top Social Themes" />
                    <TopThemeLineChart parentThemeName="governance" title="Top Governance Themes" />
                </div>
                <div className="w-full xl:w-1/4">
                    <RootThemesDonutChart />
                    <PlatformPolarAreaChart />
                </div>
            </div>
        </ContainerWrapper>
    );
};

export default DashboardHome;
