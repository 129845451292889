import React, { Dispatch, SetStateAction, useState } from 'react';

import { useTopSectorThemes } from '../../executives/api/getTopSectorThemes';
import { useTopCompanyThemes } from '../../executives/api/getTopCompanyThemes';
import { useTopThemes } from '../../executives/api/getTopThemes';

import SkeletonCard from '~/components/skeletonCard';
import { useExecutive } from '../../executives/api/getExecutive';
import { Card, CardContent } from '~/components/ui/card';
import stringToClassName from '~/utils/stringToClassName';
import { accentByParentThemeLight } from '~/components/themeAccents';

const ThemeComparisonList = ({
    themes,
    hoverTheme,
    setHoverTheme,
}: {
    themes: any[];
    hoverTheme: string;
    setHoverTheme: Dispatch<SetStateAction<string>>;
}): JSX.Element => {
    return (
        <>
            {themes.map((theme: any, index: number) => {
                const themeCssClass = stringToClassName(theme.themeName);
                const accentColor = accentByParentThemeLight(theme.parentThemeName);
                return (
                    <div
                        key={theme.themeName}
                        className={`flex flex-row align-top ${themeCssClass} `}
                        onMouseEnter={() => setHoverTheme(themeCssClass)}
                        onMouseLeave={() => setHoverTheme('')}
                    >
                        <h3 className="font-light py-1">{index + 1}.</h3>
                        <h3
                            className={`px-2 py-1 ${
                                themeCssClass === hoverTheme ? 'bg-' + accentColor : ''
                            } rounded-xl`}
                        >
                            {theme.themeName}
                        </h3>
                    </div>
                );
            })}
        </>
    );
};

const ExecutiveThemeComparison = ({
    executiveId,
    noPadding = false,
}: {
    executiveId: string;
    noPadding: boolean;
}): JSX.Element => {
    const [hoverTheme, setHoverTheme] = useState<string>('');
    const { executive, isLoading } = useExecutive(executiveId);

    const { themes: topSectorThemes } = useTopSectorThemes(executiveId);
    const { themes: topCompanyThemes } = useTopCompanyThemes(executiveId);
    const { themes: topThemes } = useTopThemes(executiveId);

    if (!executive) {
        return <></>;
    }
    if (isLoading) {
        return <SkeletonCard />;
    }
    if (!topThemes) {
        return <></>;
    }
    return (
        <>
            <Card className={`border-0 shadow-none ${noPadding ? 'p-0' : ''}`}>
                <CardContent className={`pt-6 ${noPadding ? 'p-0' : ''}`}>
                    <div className="flex flex-row justify-between">
                        <div>
                            <div className="flex flex-row mb-2">
                                <img
                                    src={executive.profile_thumbnail_url}
                                    alt="Executive Thumbnail"
                                    className="bg-white rounded-xl w-full max-w-[25px] max-h-[25px] object-cover aspect-square mr-1"
                                />
                                <h2 className="font-bold">{executive.name}</h2>
                            </div>
                            {topThemes ? (
                                <ThemeComparisonList
                                    themes={topThemes}
                                    hoverTheme={hoverTheme}
                                    setHoverTheme={setHoverTheme}
                                />
                            ) : null}
                        </div>
                        <div>
                            <div className="flex flex-row mb-2">
                                <img
                                    src={executive?.company.logo_url}
                                    alt="Company Logo Thumbnail"
                                    className="bg-white rounded-xl w-full max-w-[25px] max-h-[25px] object-contain mr-1"
                                />
                                <h2 className="font-bold">{executive?.company.name}</h2>
                            </div>
                            {topCompanyThemes ? (
                                <ThemeComparisonList
                                    themes={topCompanyThemes}
                                    hoverTheme={hoverTheme}
                                    setHoverTheme={setHoverTheme}
                                />
                            ) : null}
                        </div>
                        <div>
                            <h2 className="font-bold mb-2">Sector - {executive?.company?.sector?.name}</h2>
                            {topSectorThemes ? (
                                <ThemeComparisonList
                                    themes={topSectorThemes}
                                    hoverTheme={hoverTheme}
                                    setHoverTheme={setHoverTheme}
                                />
                            ) : null}
                        </div>
                    </div>
                </CardContent>
            </Card>
        </>
    );
};

export default ExecutiveThemeComparison;
