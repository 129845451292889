import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Api from '~/utils/apiClient';
import Spinner from '~/components/spinner';
import Select from 'react-select';

interface ComponentProps {
    indexScopeName?: string;
    children?: JSX.Element;
}

interface SelectOption {
    id: number;
    filter_string: string;
    name: string;
}

const IndexScopeSelector = (props: ComponentProps): JSX.Element => {
    const { children, indexScopeName } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [indexScopes, setIndexScopes] = useState<SelectOption[]>();
    const [selectedScope, setSelectedScope] = useState<any>();

    const getIndexScopes = async () => {
        const indexResponse = await Api.get(`/api/admin/index-scopes`);
        const mappedIndexScopes = indexResponse?.scopes?.map((indexScope: SelectOption) => {
            return {
                label: indexScope.name,
                value: indexScope.id,
            };
        });
        setIndexScopes(mappedIndexScopes);
    };

    useEffect(() => {
        getIndexScopes();
    }, []);

    const updateIndexScope = async () => {
        await Api.update(`/api/admin/index-scopes/team`, { scope: { index_scope_id: selectedScope } });
        location.reload();
    };

    const clearIndexScope = async () => {
        await Api.update(`/api/admin/index-scopes/team`, { scope: { index_scope_id: null } });
        location.reload();
    };

    const closeModal = (): void => {
        setIsOpen(false);
    };

    return (
        <div>
            <div onClick={() => setIsOpen(true)}>{children}</div>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                contentLabel="Modal"
                className="cm-modal-body"
                overlayClassName="cm-modal-overlay"
                ariaHideApp={false}
            >
                <button className="absolute right-5 text-2xl hover:opacity-80" onClick={closeModal}>
                    <i className="fal fa-times text-vSubtle"></i>
                </button>

                <h2 className="font-primary text-2xl">Index Scopes</h2>
                {indexScopeName ? (
                    <p className="font-primary text-base">
                        Current Scope Name: <b>{indexScopeName}</b>
                    </p>
                ) : (
                    <p className="font-primary text-base">No Scope Set</p>
                )}
                {indexScopes?.length ? (
                    <Select
                        options={indexScopes}
                        isClearable
                        className="multiselect-filter mt-10"
                        placeholder={`Select an Index Scope`}
                        onChange={selectedOpt => {
                            if (selectedOpt) {
                                setSelectedScope((selectedOpt as any)?.value);
                            }
                        }}
                    />
                ) : (
                    <Spinner />
                )}

                <div className="flex flex-row justify-between items-center mt-10">
                    <button type="submit" className={`btn-primary text-lg`} onClick={() => updateIndexScope()}>
                        Update Scope
                    </button>
                    <button className={`btn-primary-outline`} onClick={() => clearIndexScope()}>
                        Clear Index Scope
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default IndexScopeSelector;
