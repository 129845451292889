import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '~/hooks/useAuth';
import BillingPortalLink from './billingLink';

const AccountMenu = (): JSX.Element => {
    const { isAdmin } = useAuth();

    return (
        <section>
            <div className="flex flex-col grow">
                <NavLink
                    end
                    to={`/app/settings/profile`}
                    className={({ isActive }) => 'cm-menu-link' + (isActive ? ' verdant' : '')}
                >
                    Your Profile
                </NavLink>

                <NavLink
                    end
                    to={`/app/settings/profile/password`}
                    className={({ isActive }) => 'cm-menu-link' + (isActive ? ' verdant' : '')}
                >
                    Password
                </NavLink>
                {isAdmin && (
                    <>
                        <div className="mb-3 border-t-2 border-gray-200 xl:mb-5"></div>
                        <NavLink
                            end
                            to={`/app/settings/organization`}
                            className={({ isActive }) => 'cm-menu-link' + (isActive ? ' verdant' : '')}
                        >
                            Organization
                        </NavLink>
                        {/* <BillingPortalLink /> */}
                    </>
                )}
            </div>
            <div>
                <div className="mb-3 border-t-2 border-gray-200 xl:mb-5"></div>
                <a
                    href="/users/sign_out"
                    className="mb-3 text-lg font-semibold text-red-500 2xl:text-xl font-primary hover:text-red-600 xl:mb-5"
                >
                    Log Out
                </a>
            </div>
        </section>
    );
};

export default AccountMenu;
