import React from 'react';
import { Link } from 'react-router-dom';
import humanizeDate from '~/utils/humanizeDate';

const ResultCard = ({ hit }: { hit: any }): JSX.Element => {
    return (
        <div
            key={hit.id}
            className="flex flex-col items-start w-full p-5 space-x-0 bg-white border-2 border-transparent rounded-2xl"
        >
            <Link
                to={`/app/executives/${hit.slug || hit.route || hit.uuid}`}
                className="flex flex-col items-start w-full grow hover:text-verdant cursor-pointer"
            >
                {hit.profile_thumbnail_url ? (
                    <div style={{ minHeight: 75 }}>
                        <img
                            alt="executive profile thumbnail"
                            src={hit.profile_thumbnail_url}
                            className="h-auto max-w-xs bg-white rounded-xl aspect-square"
                            style={{ maxWidth: 75, maxHeight: 75 }}
                        />
                    </div>
                ) : null}
                <div className="flex flex-col grow">
                    <h2 className="w-full text-xl font-bold text-black font-primary text-left mt-1">{hit.name}</h2>
                    <p>{hit.title}</p>
                </div>
            </Link>

            {hit?.company?.name ? (
                <div className="mt-2 pt-2 border-t border-gray-200 flex flex-col w-full">
                    <Link
                        to={`/app/company/${hit.company.slug || hit.company.route}`}
                        className="flex flex-row w-full items-center"
                    >
                        {hit?.company?.logo_url ? (
                            <img
                                alt="company logo"
                                src={hit.company.logo_url}
                                className="h-auto max-w-xs bg-white aspect-square mr-2"
                                style={{ maxWidth: 25, maxHeight: 25 }}
                            />
                        ) : null}
                        <p className="text-sm text-gray-500">{hit.company.name}</p>
                    </Link>
                </div>
            ) : null}
            {hit?.['latest_content_at'] ? (
                <p className="text-muted-foreground mt-2 text-sm">
                    Latest content from {humanizeDate(hit?.['latest_content_at'])}
                </p>
            ) : null}
        </div>
    );
};

export default ResultCard;
