// Vendor Imports
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import Linkify from 'linkify-react';
import { ErrorBoundary } from 'react-error-boundary';

// Util Imports
import ApiClient from '~/utils/apiClient';
import Loading from '~/components/loading';
import { useAuth } from '~/hooks/useAuth';
import useDocumentTitle from '~/hooks/useDocumentTitle';

// Layout Imports
import { Container, ContainerWrapper } from '../layout/container';
import Sidebar from '../layout/sidebar';
import CompanyLink from '../company/components/companyLink';
import { ThemeBar } from '~/components/themeBar';
import DocumentSlider from './components/slider';
import Tippy from '@tippyjs/react';
import DocumentPageRows from './components/rows';
import DocumentThemes from './components/theme';
import ExportToCsv from './components/exportToCsv';

type Layout = 'slider' | 'rows';

const Document = (): JSX.Element => {
    const { companyId, documentId, documentPageId } = useParams<any>();

    const [document, setDocument] = useState<any>();
    const [selectedThemes, setSelectedThemes] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState<any>({});
    const [layout, setLayout] = useState<Layout>('rows');
    const [currentBackground, setCurrentBackground] = useState<string>();
    const [selectedDocumentPages, setSelectedDocumentPages] = useState<any[]>([]);

    const { isAdmin } = useAuth();

    useDocumentTitle(`Document`);

    const getDocument = async () => {
        const documentResponse = await ApiClient.get(`/api/documents/${documentId}`);
        setDocument(documentResponse);

        if (documentResponse?.pages?.length > 0) {
            setSelectedDocumentPages(documentResponse.pages);
            setCurrentPage(documentResponse.pages[0]);
            setCurrentBackground(documentResponse.pages[0].thumbnail_url);
        }
    };

    useEffect(() => {
        getDocument();
    }, []);

    useEffect(() => {
        if (document?.pages?.length > 0) {
            if (selectedThemes?.length === 0) {
                setSelectedDocumentPages(document?.pages);
            } else {
                setSelectedDocumentPages([]);
                document.pages.filter((page: any) => {
                    if (
                        page?.themes?.length > 0 &&
                        page.themes.some((theme: any) => selectedThemes.includes(theme.id))
                    ) {
                        setSelectedDocumentPages(selectedDocumentPages => [...selectedDocumentPages, page]);
                    }
                });
            }
        }
    }, [selectedThemes]);

    return (
        <ContainerWrapper>
            <Sidebar header="Document Details" expanded>
                {document && !document?.error && (
                    <div className="flex flex-col space-y-3 lg:space-y-0 lg:space-x-3 lg:flex-row justify-between mb-3">
                        <Tippy content="Change layout of pages">
                            <button
                                onClick={() => {
                                    if (layout == 'rows') {
                                        setLayout('slider');
                                    } else {
                                        setLayout('rows');
                                    }
                                }}
                                className="btn-secondary font-semibold"
                            >
                                <div
                                    className={
                                        layout === 'rows' ? 'flex flex-col text-center justify-center' : 'hidden'
                                    }
                                >
                                    <i className="fa-light fa-table-list text-lg w-full"></i>
                                    <span className="text-xs text-gray-800 mt-2">Rows</span>
                                </div>
                                <div
                                    className={
                                        layout === 'slider' ? 'flex flex-col text-center justify-center' : 'hidden'
                                    }
                                >
                                    <i className="fa-light fa-gallery-thumbnails text-lg w-full"></i>
                                    <span className="text-xs text-gray-800 mt-2">Slides</span>
                                </div>
                            </button>
                        </Tippy>
                        {/* <Tippy content="Advanced Filtering">
                            <a
                                href={document?.['media_url']}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn-secondary font-semibold flex flex-col text-center justify-center"
                            >
                                <i className="fa-light fa-magnifying-glass text-lg"></i>
                                <span className="text-xs text-gray-800 mt-2">Search</span>
                            </a>
                        </Tippy> */}
                        <Tippy content="Download Original File">
                            <a
                                href={document?.['media_url']}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn-secondary font-semibold flex flex-col text-center justify-center"
                            >
                                <i className="fa-light fa-download text-lg"></i>
                                <span className="text-xs text-gray-800 mt-2">Download</span>
                            </a>
                        </Tippy>

                        <ExportToCsv
                            contentUuids={document.pages.map((page: any) => page.content_uuid)}
                            documentUuid={document.uuid}
                        >
                            <Tippy content="Export to CSV">
                                <button className="btn-secondary font-semibold flex flex-col text-center justify-center">
                                    <i className="fa-light fa-file-zipper text-lg w-full"></i>
                                    <span className="text-xs text-gray-800 mt-2">Export</span>
                                </button>
                            </Tippy>
                        </ExportToCsv>
                    </div>
                )}

                {document && !document?.error && (
                    <div className="text-sm w-100 2xl:text-lg grow pt-3">
                        {Object.entries(document).map(([key, value]: [any, any]) => {
                            if (!value) return null;

                            if (
                                typeof value === 'string' ||
                                typeof value === 'number' ||
                                Array.isArray(value) ||
                                typeof value === 'object'
                            ) {
                                if (
                                    key.endsWith('_id') ||
                                    [
                                        'display_name',
                                        'uuid',
                                        'media_url',
                                        'created_at',
                                        'updated_at',
                                        'pages',
                                        'assets',
                                        'summary',
                                        'theme_stats',
                                        'themes',
                                        'doc_type',
                                        'thumbnail_url',
                                        'description',
                                    ].includes(key)
                                ) {
                                    return null;
                                }
                                if (Array.isArray(value) && !value.length) {
                                    return null;
                                }
                                if (key.endsWith('_at')) {
                                    value = moment(value).format('LL');
                                }
                                return (
                                    <div
                                        className="flex flex-col py-3 border-t border-gray-200 xl:flex-row last:border-b first:border-t-0"
                                        key={key}
                                    >
                                        <div className="w-full xl:w-1/3">
                                            <p className="font-semibold text-black font-primary xl:mr-2">
                                                {key
                                                    .replace('_at', '')
                                                    .split('_')
                                                    .map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase())
                                                    .join(' ')}
                                            </p>
                                        </div>
                                        <div className="w-full break-words xl:w-2/3 linkify">
                                            {typeof value === 'object' &&
                                                value !== null &&
                                                value?.name &&
                                                value?.id && (
                                                    <span>
                                                        {key === 'company' ? (
                                                            <CompanyLink id={value.slug || value.id}>
                                                                {value.name}
                                                            </CompanyLink>
                                                        ) : (
                                                            <span key={value.id}>{value.name}</span>
                                                        )}
                                                    </span>
                                                )}

                                            {Array.isArray(value) && value.length && (
                                                <span>
                                                    {value.map((v: any) => {
                                                        if (typeof v === 'object' && !Array.isArray(v) && v !== null) {
                                                            if (v?.name && v?.id) {
                                                                return (
                                                                    <span key={v.id}>
                                                                        {v.name} <br />
                                                                    </span>
                                                                );
                                                            } else {
                                                                return <></>;
                                                            }
                                                        } else {
                                                            return (
                                                                <span key={v}>
                                                                    {v} <br />
                                                                </span>
                                                            );
                                                        }
                                                    })}
                                                </span>
                                            )}

                                            {!Array.isArray(value) && typeof value !== 'object' ? (
                                                <>
                                                    <Linkify tagName="p" options={{ target: '_blank' }}>
                                                        {value}
                                                    </Linkify>
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                );
                            }
                        })}
                        {document && !document?.error && document.theme_stats && (
                            <ErrorBoundary fallback={<></>}>
                                <ThemeBar
                                    themeStats={document.theme_stats}
                                    className="mt-5 mb-3 border-t border-gray-200"
                                />
                            </ErrorBoundary>
                        )}

                        {document?.['themes']?.length ? (
                            <div className="flex flex-col py-3">
                                <DocumentThemes
                                    themes={document?.['themes']}
                                    selectedThemes={selectedThemes}
                                    setSelectedThemes={setSelectedThemes}
                                />
                            </div>
                        ) : null}
                        {/* {document?.['media_url']?.length ? (
                            <a
                                href={document?.['media_url']}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center pt-5 pb-3 font-semibold font-primary text-verdant hover:text-verdantDark"
                            >
                                <i className="mr-2 fas fa-download fa-fw"></i>
                                Download Original
                            </a>
                        ) : null} */}

                        {isAdmin ? (
                            <a
                                href={`/admin/documents/${document.uuid}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center pt-5 pb-3 font-semibold font-primary text-verdant hover:text-verdantDark"
                            >
                                <i className="mr-2 fas fa-user-lock fa-fw"></i>
                                Open in Admin Panel
                            </a>
                        ) : null}
                    </div>
                )}
            </Sidebar>
            <Container noPadding>
                {!document && <Loading isLoading={true} />}
                {document && !document?.error && layout === 'slider' && (
                    <DocumentSlider
                        document={document}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        currentBackground={currentBackground}
                        setCurrentBackground={setCurrentBackground}
                    />
                )}
                {document && !document?.error && layout === 'rows' && (
                    <DocumentPageRows document={document} pages={selectedDocumentPages} />
                )}

                {document && document.error && (
                    <div className="container mt-10 text-center">
                        <h1 className="text-2xl font-primary">404 - Document Not Found</h1>
                        <p className="text-lg font-primary">
                            Oops! We aren&apos;t able to find the document you requested.
                        </p>
                    </div>
                )}
            </Container>
        </ContainerWrapper>
    );
};

export default Document;
