import React, { useEffect, useRef } from 'react';
import { tippy } from '@tippyjs/react';
import { cn } from '~/utils/ui';

export interface ThemeBarProps {
    themeStats: {
        e_count: number;
        s_count: number;
        g_count: number;
        total_count: number;
    };
    height?: number;
    className?: string;
}

export const ThemeBar = ({ themeStats, className, height = 10 }: ThemeBarProps): JSX.Element => {
    const eRef = useRef(null);
    const sRef = useRef(null);
    const gRef = useRef(null);
    const total = themeStats.total_count;
    const eWidth = (themeStats.e_count / total) * 100 || 0;
    const sWidth = (themeStats.s_count / total) * 100 || 0;
    const gWidth = (themeStats.g_count / total) * 100 || 0;

    useEffect(() => {
        const total = themeStats.total_count;
        const eWidth = (themeStats.e_count / total) * 100;
        const sWidth = (themeStats.s_count / total) * 100;
        const gWidth = (themeStats.g_count / total) * 100;

        if (eRef.current) {
            tippy(eRef.current, {
                content: `${eWidth.toFixed(0)}% Environmental Themes`,
            });
        }
        if (sRef.current) {
            tippy(sRef.current, {
                content: `${sWidth.toFixed(0)}% Social Themes`,
            });
        }
        if (gRef.current) {
            tippy(gRef.current, {
                content: `${gWidth.toFixed(0)}% Governance Themes`,
            });
        }
    }, [themeStats]);

    return (
        <>
            <svg width="100%" height={height} className={cn(`rounded mb-3`, className)}>
                <rect ref={eRef} x="0" y="0" width={`${eWidth}%`} height={height} className="fill-blue-500" />

                <rect
                    ref={sRef}
                    x={`${eWidth}%`}
                    y="0"
                    width={`${sWidth}%`}
                    height={height}
                    className="fill-yellow-500"
                />
                <rect
                    ref={gRef}
                    x={`${eWidth + sWidth}%`}
                    y="0"
                    width={`${gWidth}%`}
                    height={height}
                    className="fill-purple-500"
                />
            </svg>
        </>
    );
};
