import useSWR from 'swr';
import ApiClient from '~/utils/apiClient';
import { Executive } from '../types';

export const getExecutive = async (url: string) => {
    const response = await ApiClient.get(url);
    if (response.error) {
        throw new Error(response.error);
    }
    return response;
};

export const useExecutive = (executiveId: string) => {
    const { data, error, isLoading } = useSWR<Executive>(`/api/executives/${executiveId}`, getExecutive);

    return {
        executive: data,
        isLoading,
        isError: error,
    };
};
