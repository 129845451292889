import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { useSearchUrls } from '~/hooks/useSearchUrls';
import { useAuth } from '~/hooks/useAuth';
import ApiClient, { showNotificationAfterApiCall } from '~/utils/apiClient';
import downloadBlob from '~/utils/downloadBlob';

interface ComponentProps {
    companyId: string;
    companyName: string;
}

const ReportMenu = (props: ComponentProps): JSX.Element => {
    const { companyId, companyName } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { generateSearchByCompanyUrl } = useSearchUrls();
    const { isAdmin } = useAuth();

    const downloadExport = async (): Promise<void> => {
        setIsLoading(true);
        const response = await ApiClient.export(`/api/exports/company`, {
            export: {
                media: false,
                file_type: 'csv',
                company_id: companyId,
            },
        });
        showNotificationAfterApiCall(response, 'Success! Export started downloading');
        if (!response?.error) {
            downloadBlob(response, `cometrics-${companyId}-${Date.now()}.zip`);
        }
        setIsLoading(false);
    };

    return (
        <div className="flex justify-center w-full mb-5 background-offset xl:max-w-2xl">
            <div className="flex flex-col justify-between w-full text-xl font-bold cursor-pointer rounded-3xl background-offset">
                {/* <NavLink
                    to={`/app/company/${companyId}/environment`}
                    className={({ isActive }) =>
                        'text-center rounded-3xl bg-white p-2 hover:border-blue-500 hover:border-2 border-t-blue-500 border-t-2 border-b-2 border-transparent mb-5' +
                        (isActive ? ' text-white bg-verdant border-verdant' : '')
                    }
                >
                    Environment
                </NavLink>
                <NavLink
                    to={`/app/company/${companyId}/social`}
                    className={({ isActive }) =>
                        'text-center rounded-3xl bg-white p-2 hover:border-yellow-500 hover:border-2 border-t-yellow-500 border-t-2 border-b-2 border-transparent mb-5' +
                        (isActive ? ' text-white bg-yellow-500 border-yellow-500' : '')
                    }
                >
                    Social
                </NavLink>
                <NavLink
                    to={`/app/company/${companyId}/governance`}
                    className={({ isActive }) =>
                        'text-center rounded-3xl bg-white p-2 hover:border-purple-500 hover:border-2 border-t-purple-500 border-t-2 border-b-2 border-transparent mb-5' +
                        (isActive ? ' text-white bg-purple-500 border-purple-500' : '')
                    }
                >
                    Governance
                </NavLink> */}
                <NavLink
                    to={`/app/company/${companyId}/competitors`}
                    className={({ isActive }) =>
                        'text-center rounded-3xl bg-white p-2 hover:border-verdant border-2 border-white mb-5' +
                        (isActive ? ' text-white bg-verdant border-verdant' : '')
                    }
                >
                    Competitors
                </NavLink>
                <NavLink
                    to={generateSearchByCompanyUrl(companyName)}
                    className={({ isActive }) =>
                        'text-center rounded-3xl bg-white p-2 hover:border-verdant border-2 border-white mb-5' +
                        (isActive ? ' text-white bg-verdant border-verdant' : '')
                    }
                >
                    Content Feed
                </NavLink>
                <button
                    onClick={downloadExport}
                    className={`${
                        isLoading ? 'disabled opacity-50 ' : ''
                    }text-center rounded-3xl bg-white p-2 hover:border-verdant border-2 border-white`}
                >
                    Export Themes
                </button>
                {isAdmin ? (
                    <>
                        <a
                            href={`/internal/companies/${companyId}`}
                            className="text-center rounded-3xl bg-white p-2 mt-5 hover:border-verdant border-2 border-white"
                        >
                            Admin
                        </a>
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default ReportMenu;
