import React, { useEffect, useState } from 'react';
import ApiClient from '~/utils/apiClient';
import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend } from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import Spinner from '~/components/spinner';
import { useSearchUrls } from '~/hooks/useSearchUrls';

interface ComponentProps {
    title?: string;
    companyName?: string;
    maxWidth?: string;
    noHeading?: boolean;
}

const PlatformPolarAreaChart = (props: ComponentProps): JSX.Element => {
    ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);
    const { generateSearchByCompanyUrl } = useSearchUrls();

    const { title, companyName, maxWidth, noHeading } = props;

    const [companyData, setCompanyData] = useState<any>();
    const [chartData, setChartData] = useState<any>();

    const options = {
        responsive: true,
        elements: {
            point: {
                radius: 0,
            },
        },
        plugins: {
            legend: {
                labels: {
                    font: {
                        family: "'AvenirLight', 'Montserrat', sans-serif" as const,
                    },
                },
                position: 'top' as const,
                display: false,
            },
            title: {
                display: false,
                text: '',
            },
        },
    };

    const getChartData = async () => {
        const data = await ApiClient.get(
            `/api/stats/platform-breakdown${companyName?.length ? `?company_name=${companyName}` : ''}`,
        );
        if (data && !data?.error) {
            setCompanyData({
                companyName: data?.company,
                logoUrl: data?.logo_url,
            });

            const dataSets = {
                labels: data?.labels,
                datasets: data?.datasets,
            };
            setChartData(dataSets);
        }
    };

    useEffect(() => {
        getChartData();
    }, [companyName]);

    return (
        <div className="flex flex-col p-5 mb-5 bg-white rounded-xl 2xl:mb-10">
            <div className="flex flex-col items-center justify-between">
                <div className="flex flex-row items-center justify-between w-full mb-3">
                    <h1 className="text-base font-semibold text-black font-primary 2xl:text-lg">
                        {title ? title : 'Platform Breakdown'}
                        {companyData?.companyName && !title && !noHeading ? (
                            <span>
                                <a
                                    className="text-base text-black font-primary grow hover:text-verdant"
                                    href={generateSearchByCompanyUrl(companyData.companyName)}
                                >
                                    {companyData.companyName}{' '}
                                </a>
                                Platform Breakdown
                            </span>
                        ) : null}
                    </h1>
                    {companyData && companyData?.logoUrl && !title && !noHeading ? (
                        <a href={generateSearchByCompanyUrl(companyData.companyName)}>
                            <img src={companyData?.logoUrl} className="w-8 h-auto min-h-full" />
                        </a>
                    ) : null}
                </div>
                {chartData ? (
                    <PolarArea options={options} data={chartData} style={{ minHeight: 200, maxWidth: maxWidth }} />
                ) : (
                    <Spinner />
                )}
            </div>
        </div>
    );
};

export default PlatformPolarAreaChart;
