import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { Formik, Field, Form, FormikHelpers } from 'formik';

import Wrapper from '../components/wrapper';
import Header from '../components/header';

import Api from '~/utils/apiClient';
import useDocumentTitle from '~/hooks/useDocumentTitle';

interface FormInputs {
    company_name: string;
    website: string;
    linkedin_url: string;
    twitter_url: string;
    threads_url: string;
    instagram_url: string;
    additional_details: string;
}

const Companies = (): JSX.Element => {
    useDocumentTitle('Nominate a Company');
    const [companyName, setCompanyName] = useState<string>('');
    return (
        <Wrapper>
            <>
                <Header
                    title="Nominate a Company"
                    description="If you would like to nominate a Company for inclusion in the ISSUTRKR database, please submit the following fields. Please remember our primary focus is on Fortune 500 companies and related."
                />
                <Formik
                    initialValues={{
                        company_name: '',
                        website: '',
                        linkedin_url: '',
                        twitter_url: '',
                        threads_url: '',
                        instagram_url: '',
                        additional_details: '',
                    }}
                    onSubmit={async (values: FormInputs, { setSubmitting, resetForm }: FormikHelpers<FormInputs>) => {
                        const response = await Api.create('/api/nominations', {
                            nomination: {
                                company_name: values.company_name,
                                website: values.website,
                                additional_details: values.additional_details,
                                details: { ...values },
                            },
                        });
                        if (response && !response.error) {
                            setCompanyName(values.company_name);
                            toast.success('Your company nomination has been received!');
                            setSubmitting(false);
                            resetForm();
                        } else {
                            toast.error('There was a problem submitting your nomination.');
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Field
                                id="company_name"
                                name="company_name"
                                placeholder="Company Name"
                                className="w-full p-2 text-lg cm-input"
                                required
                            />
                            <Field
                                id="website"
                                name="website"
                                placeholder="Website"
                                className="w-full p-2 mt-5 text-lg cm-input"
                            />
                            <Field
                                id="linkedin_url"
                                name="linkedin_url"
                                placeholder="LinkedIn URL"
                                className="w-full p-2 mt-5 text-lg cm-input"
                            />
                            <Field
                                id="twitter_url"
                                name="twitter_url"
                                placeholder="X / Twitter URL"
                                className="w-full p-2 mt-5 text-lg cm-input"
                            />
                            <Field
                                id="threads_url"
                                name="threads_url"
                                placeholder="Threads URL"
                                className="w-full p-2 mt-5 text-lg cm-input"
                            />
                            <Field
                                id="instagram_url"
                                name="instagram_url"
                                placeholder="Instagram URL"
                                className="w-full p-2 mt-5 text-lg cm-input"
                            />
                            <Field
                                id="additional_details"
                                name="additional_details"
                                placeholder="Any other details you would like to share or any other social media links"
                                as="textarea"
                                rows="10"
                                className="w-full p-2 mt-5 text-lg cm-input"
                            />

                            <button
                                type="submit"
                                className="mt-5 text-lg btn-primary 2xl:text-xl"
                                disabled={isSubmitting}
                            >
                                Submit Nomination
                            </button>

                            <div
                                className={`mt-5 w-full bg-verdant text-center rounded-2xl py-2 px-1 ${
                                    companyName ? '' : 'hidden'
                                }`}
                            >
                                <p className="text-lg text-white font-primary">
                                    <i className="mr-3 far fa-check"></i> We&apos;ve received your nomination for{' '}
                                    {companyName}! If your company is added to our database, we will email you with
                                    details on how to subscribe for access.
                                </p>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        </Wrapper>
    );
};

export default Companies;
