const stringToClassName = (input: string): string => {
    return input
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9\-_]/g, '-')
        .replace(/^-+|-+$/g, '')
        .replace(/--+/g, '-');
};

export default stringToClassName;
