import React, { useState } from 'react';
import Spinner from '~/components/spinner';
import ApiClient, { showNotificationAfterApiCall } from '~/utils/apiClient';
import downloadBlob from '~/utils/downloadBlob';

interface ComponentProps {
    children: React.ReactNode;
    documentUuid: string;
    contentUuids: string[];
    includeMedia?: boolean;
}

const ExportToCsv = (props: ComponentProps): JSX.Element => {
    const { children, includeMedia, documentUuid, contentUuids } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const downloadZippedContent = async (): Promise<void> => {
        setIsLoading(true);
        const response = await ApiClient.export(`/api/exports`, {
            export: { content_uuids: contentUuids, media: !!includeMedia, file_type: 'csv' },
        });
        showNotificationAfterApiCall(response, 'Success! Export started downloading');
        if (!response?.error) {
            downloadBlob(response, `cometrics-${documentUuid}-${Date.now()}.zip`);
        }
        setIsLoading(false);
    };

    return (
        <div
            onClick={() => {
                if (!isLoading) {
                    downloadZippedContent();
                }
            }}
            className={`${isLoading ? 'disabled opacity-50 ' : ''}flex flex-row justify-between items-center`}
        >
            {children}
            {isLoading ? (
                <span className="ml-3">
                    {' '}
                    <Spinner className={'justify-end items-center'} height={20} width={20} />
                </span>
            ) : null}
        </div>
    );
};

export default ExportToCsv;
