import React from 'react';
import TrendingThemes from '../../visualization/trendingThemes';
import RootThemesDonutChart from '../../visualization/rootThemesDonutChart';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

interface ComponentProps {
    companyId: string;
}

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const TrendingSnapshotThemes = (props: ComponentProps): JSX.Element => {
    const { companyId } = props;

    return (
        <div className="flex flex-col w-full">
            {/* <div className="basis-1/2 inline-block text-left font-bold text-2xl">Environmental</div>
            <div className="flex flex-col ">
                <div className="rounded-xl bg-white p-5 w-1/2">
                    <Radar data={data} options={options} />
                </div>
            </div> */}
            <div className="flex flex-col xl:flex-row xl:space-x-10">
                <div className="w-full xl:w-1/2">
                    <div className="flex flex-row my-5 justify-between">
                        <div className="self-center basis-1/2 inline-block align-middle font-bold text-2xl">
                            Total Content
                        </div>
                    </div>
                    <div className="font-bold">
                        <RootThemesDonutChart companyPage={true} companyId={companyId} />
                    </div>
                </div>
                <div className="w-full xl:w-1/2">
                    <div className="flex flex-row my-5 justify-between">
                        <div className="self-center basis-1/2 inline-block align-middle font-bold text-2xl">
                            Trending Themes
                        </div>
                        <div className={`text-right basis-1/2 inline-block align-middle font-bold`}>Past 30 days</div>
                    </div>
                    <div className="rounded-xl bg-white flex flex-col xl:flex-row p-5">
                        <div className="align-middle">
                            <TrendingThemes company_page={true} companyId={companyId} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrendingSnapshotThemes;
