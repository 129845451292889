import React from 'react';
import { useRelatedExecutives } from '../api/getRelatedExecutives';

import { NavLink, useParams } from 'react-router-dom';
import { useExecutive } from '../api/getExecutive';
import { Executive } from '../types';

const RecentContent = (): JSX.Element => {
    const { executiveId } = useParams<string>();

    if (!executiveId) return <></>;
    const { executives } = useRelatedExecutives(executiveId);
    const { executive } = useExecutive(executiveId);

    if (!executive) {
        return <></>;
    }

    return (
        <section className="mt-5 pt-5 border-t border-gray-200 space-y-1.5 p-6">
            <h2 className=" mb-2">Related Executives</h2>
            <div className="flex flex-col">
                {executives?.map((exec: Executive) => (
                    <div key={exec.id} className="flex flex-row mt-3 align-top">
                        <img
                            src={exec.profile_thumbnail_url}
                            alt="Executive Thumbnail"
                            className="bg-white rounded-xl w-full max-w-[50px] max-h-[50px] object-cover aspect-square"
                        />

                        <NavLink to={`/app/executives/${exec.route}`} className="ml-3">
                            <h2>{exec.name}</h2>
                            <p className="text-sm text-muted-foreground">
                                {exec.title} @ {exec.company.name}
                            </p>
                        </NavLink>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default RecentContent;
