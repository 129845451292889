import React, { useState } from 'react';

import ThemePill from '../../content/themePill';
import Save from '~/dashboard/saved/save';
import ExportToCsv from './exportToCsv';
import Tippy from '@tippyjs/react';
import { Link } from 'react-router-dom';

interface Props {
    document: any;
    pages?: any;
}

const DocumentPageRows = ({ document, pages }: Props) => {
    return (
        <div className="flex flex-col w-full background-offset pt-5">
            {pages?.length ? (
                <>
                    {pages.map((page: any) => {
                        if (page?.summary || page?.themes?.length || page?.thumbnail_url) {
                            return (
                                <div className="px-5 mb-5" key={page.uuid}>
                                    <div className="p-5 bg-white rounded-xl">
                                        <div className="flex flex-col xl:flex-row">
                                            {page?.thumbnail_url ? (
                                                <div className="w-full xl:w-1/4 mr-5">
                                                    <img
                                                        alt={'Document Page Thumbnail'}
                                                        src={page?.thumbnail_url}
                                                        className="max-w-full mx-auto"
                                                    />
                                                </div>
                                            ) : null}

                                            <div className="flex flex-col w-full xl:w-3/4">
                                                <p
                                                    className={`text-sm break-words font-primary 2xl:text-base ${
                                                        page?.summary?.length ? 'grow' : ''
                                                    }`}
                                                >
                                                    {page?.summary}
                                                </p>

                                                <div className="flex flex-row justify-between items-center border-t border-gray-100 pt-2 mt-3">
                                                    <Tippy content={`Page ${page?.page}`}>
                                                        <p className="text-sm break-words font-primary 2xl:text-base mr-5">
                                                            <i className="fa-light fa-file-lines fa-fw mr-3 text-gray-500"></i>
                                                            <span>{page?.page}</span>
                                                        </p>
                                                    </Tippy>
                                                    <div className="items-center space-x-5 flex align-middle text-sm">
                                                        {page.content_id ? (
                                                            <button className="btn-ghost">
                                                                <Save contentId={page.content_id} name={page?.summary}>
                                                                    <i className="fa-light fa-heart mr-2"></i>
                                                                    <span>Save</span>
                                                                </Save>
                                                            </button>
                                                        ) : null}
                                                        {page.content_uuid ? (
                                                            <Link
                                                                to={`/app/content/${page.content_uuid}`}
                                                                className="btn-ghost"
                                                            >
                                                                <i className="fal fa-list-ul mr-2"></i>
                                                                Details
                                                            </Link>
                                                        ) : null}
                                                        <a
                                                            href={page?.media_url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="btn-ghost"
                                                        >
                                                            <i className="fa-light fa-photo-film mr-2"></i>
                                                            <span>Download Media</span>
                                                        </a>
                                                        {page.content_id ? (
                                                            <button className="btn-ghost">
                                                                <ExportToCsv
                                                                    contentUuids={[page.content_uuid]}
                                                                    documentUuid={document.uuid}
                                                                    includeMedia={true}
                                                                >
                                                                    <i className="fa-light fa-file-zipper mr-2"></i>
                                                                    <span>Export</span>
                                                                </ExportToCsv>
                                                            </button>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {page?.themes?.length ? (
                                            <div className="flex flex-col">
                                                <div className="flex flex-row flex-wrap w-full">
                                                    {page?.themes?.map((theme: any) => (
                                                        <ThemePill
                                                            key={theme.id}
                                                            themeName={theme.name}
                                                            parentTheme={theme.parent_name}
                                                            className="mt-3 mr-3 whitespace-nowrap text-black"
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                </>
            ) : null}
        </div>
    );
};

export default DocumentPageRows;
