import React, { useEffect, useState } from 'react';

import ReactApexChart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';

import Spinner from '~/components/spinner';
import ApiClient from '~/utils/apiClient';
import useWindowSize from '~/hooks/useWindowSize';

interface ComponentProps {
    companyId: number;
    companyName: string;
    rootThemeName: string;
}

const SectorComparisonRadarGraph = (props: ComponentProps): JSX.Element => {
    const { companyId, companyName, rootThemeName } = props;

    const { width } = useWindowSize();

    const [chartData, setChartData] = useState<any>();
    const [chartOptions, setChartOptions] = useState<any>();

    const chartColor: Record<string, string> = {
        Environmental: '#3B82F6',
        Social: '#F59E0B',
        Governance: '#D946EF',
    };

    let chartHeight;
    if (width > 1280) {
        chartHeight = 800;
    } else if (width > 768) {
        chartHeight = 500;
    } else {
        chartHeight = 400;
    }

    const options: ApexOptions = {
        colors: [chartColor[rootThemeName], '#159A1F'],
        chart: {
            type: 'radar',
            width: '100%',
            height: chartHeight,
        },
        legend: {
            position: 'top',
            fontFamily: 'cmFont, AvenirLight, sans-serif',
            fontWeight: 700,
            markers: {
                width: 22,
                height: 2.5,
                radius: 25,
                offsetX: -3,
            },
        },
        grid: {
            padding: {
                left: width > 1650 ? -50 : 0,
                right: width > 1650 ? -50 : 0,
            },
        },
        plotOptions: {
            radar: {
                polygons: {
                    strokeColors: '#eff3f4',
                    connectorColors: '#eff3f4',
                },
            },
        },
        stroke: {
            show: true,
            width: 3,
        },
        fill: {
            opacity: 0.1,
        },
        markers: {
            size: 4,
            hover: {
                size: 10,
            },
        },
        xaxis: {
            categories: [],
            labels: {
                show: true,
                rotateAlways: true,
                trim: false,
                style: {
                    fontSize: '11px',
                    fontFamily: 'cmFont, AvenirLight, sans-serif',
                    fontWeight: 700,
                    colors: ['#000000', '#000000', '#000000', '#000000', '#000000'],
                },
            },
        },
    };

    const getChartData = async () => {
        setChartData(undefined);
        const data = await ApiClient.get(
            `/api/stats/theme-counts?company_id=${companyId}&root_theme_name=${rootThemeName}`,
        );

        if (data?.length && !data?.error) {
            if (options?.xaxis?.categories) {
                options.xaxis.categories = data.map((themeData: any) => themeData.theme_name);
            }

            const series = [
                {
                    name: companyName,
                    data: data.map((themeCount: any) => themeCount.theme_count),
                },
                {
                    name: 'Sector Avg',
                    data: data.map((themeCount: any) => themeCount.sector_average),
                },
            ];

            setChartOptions(options);
            setChartData(series);
        }
    };

    useEffect(() => {
        getChartData();
    }, []);

    useEffect(() => {
        getChartData();
    }, [companyId, companyName]);

    return (
        <div className="rounded-xl bg-white p-5 w-full" style={{ width: '100%' }}>
            {chartData ? (
                <ReactApexChart
                    options={chartOptions}
                    series={chartData}
                    type="radar"
                    width={'100%'}
                    height={chartHeight}
                />
            ) : (
                <Spinner />
            )}
            {chartData ? (
                <p className="text-center italic">
                    Top {rootThemeName} themes for {companyName} over the last year
                </p>
            ) : null}
        </div>
    );
};

export default SectorComparisonRadarGraph;
