import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Companies from './companies';
import Executives from './executives';

export const NominateRoutes = () => {
    return (
        <Routes>
            <Route path="/executives" element={<Executives />} />
            <Route path="/companies" element={<Companies />} />
            <Route path="*" element={<Navigate to="./executives" />} />
        </Routes>
    );
};
